import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";
function Index() {

    useEffect(() => {
        let swiper;

        // Swiper 초기화 함수
        const initializeSwiper2 = () => {
            if (swiper) {
                swiper.destroy(true, true); // 기존 Swiper 인스턴스가 있으면 삭제
            }
            swiper = new Swiper(".event-box .swiper-container", {
                loop: true,
                spaceBetween: 10,
                speed: 1500,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                observer: true,
                observeParents: true,
                pagination: {
                    el: ".event-box .swiper-pagination",
                },
            });
        };

        initializeSwiper2();

        // 컴포넌트 언마운트 시 Swiper 인스턴스 삭제
        return () => {
            if (swiper) swiper.destroy(true, true);
        };
    }, []);


    return (
        <section>
            <div className="event-box">
                <div className="event-banner">
                    <div className="swiper-container">
                        <ul className="swiper-wrapper">
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                            <li className="swiper-slide">
                                <img src="/asset/images/img_event_banner.png" className="is-pc" alt="Event Banner" />
                                <img src="/asset/images/img_event_banner_m.png" className="is-m" alt="Event Banner Mobile" />
                            </li>
                        </ul>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
                <div className="title-box mb16">
                    <h2>EVENT</h2>
                </div>
                <div className="event-tab">
                    <div className="container">
                        <ul>    
                            <li>
                                <Link className="link">
                                    진행중 이벤트
                                </Link>
                            </li>
                            <li>
                                <Link className="link">
                                    종료된 이벤트
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="event-list">
                    <div className="container">
                        <ul className="list">
                            {Array.from({ length: 15 }).map((_, index) => (
                                <li key={index}>
                                    <Link className="inner">
                                        <div
                                            className="list-image"
                                            style={{
                                                backgroundImage: "url('/asset/images/img_event_banner_m.png')",
                                            }}
                                        ></div>
                                        <div className="list-content">
                                            <p>오톡 오픈기념, 1+1 특별 증정 이벤트</p>
                                            <time>2024.09.16 ~ 2024.09.26</time>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
