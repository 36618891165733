import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { refreshToken } from '../services/Auth/auth';

const useUserStore = create(
    persist(
        (set,get) => ({
            memberTokens: '',
            userInfo: {},
            isExq: false,
            refreshingToken: false,
            isSnsLogin:false,
            updateSnsLogin: (isSns) => set({isSnsLogin: isSns}),
            updateTokens: (token) => set({ memberTokens: token }),
            updateUserInfo: (info) => set({ userInfo: info }),
            logout:()=> {

                set({
                    memberTokens: '',
                    userInfo: {},
                })
                sessionStorage.removeItem('user-storage');
                localStorage.clear()
            },
            refreshMemberToken: async () => {
                // 이미 갱신 중이라면 바로 반환
                if (get().refreshingToken) return;

                set({ refreshingToken: true });

                try {
                    const response = await refreshToken();
                    if (response.data.code === 200) {
                        // 새로운 토큰 업데이트
                        get().updateTokens(response.data.data.access_token);
                    }
                } catch (error) {
                    console.error('토큰 갱신 오류:', error);
                } finally {
                    // 갱신 완료 후 플래그 초기화
                    set({ refreshingToken: false });
                }
            },
        }),
        {
            name: 'user-storage',
        }
    )
);

export default useUserStore;

