import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import DaumPostcodeEmbed from 'react-daum-postcode';


function CreateStep3() {
    const navigate = useNavigate();
    const location = useLocation();
    const mailingData = location.state?.mailing || false;
    const smsData = location.state?.sms || false;
    const [memberType, setMemberType] = useState('일반회원');
    const memType = (memberType === '일반회원' ? 1 : 2);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        verificationCode: '',
        email: '',
        companyInfo: {
            name: '',
            businessFile: null,
            businessNumber: '',
            businessType: '',
            address: {
                zipcode: '',
                address1: '',
                address2: ''
            }
        }
    });
    
    // 우편번호 관련 상태
    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [address, setAddress] = useState({
        zonecode: '',
        roadAddress: '',
        jibunAddress: ''
    });

    // 우편번호 찾기 핸들러들
    const handleOpenPostcode = (e) => {
        e.preventDefault();
        setIsPostcodeOpen(true);
    };

    const handleComplete = (data) => {
        const newAddress = {
            zonecode: data.zonecode,
            roadAddress: data.roadAddress,
            jibunAddress: data.jibunAddress
        };

        setAddress(newAddress);
        setFormData(prevData => ({
            ...prevData,
            companyInfo: {
                ...prevData.companyInfo,
                address: {
                    zipcode: data.zonecode,
                    address1: data.roadAddress,
                    address2: ''  // 상세주소는 사용자가 직접 입력하도록 비워둡니다
                }
            }
        }));
        setIsPostcodeOpen(false);
    };

    const handleClosePostcode = () => {
        setIsPostcodeOpen(false);
    };



    const handleNext = () => {
        // 기본 정보 유효성 검사
        if (!formData.name || !formData.phone || !formData.email) {
            alert('이름, 휴대폰 번호, 이메일은 필수 입력사항입니다.');
            return;
        }

        // 휴대폰 인증 여부 확인 (verificationCode 확인 로직 필요)
        if (!formData.verificationCode) {
            alert('휴대폰 인증이 필요합니다.');
            return;
        }

        if (memberType === '도매회원') {
            // 도매회원의 경우 추가적인 유효성 검사
            if (!formData.companyInfo.name) {
                alert('회사명을 입력해주세요.');
                return;
            }
            if (!formData.companyInfo.businessFile) {
                alert('사업자등록증을 첨부해주세요.');
                return;
            }
            if (!formData.companyInfo.businessNumber) {
                alert('사업자등록번호를 입력해주세요.');
                return;
            }
            if (!formData.companyInfo.businessType) {
                alert('업종을 입력해주세요.');
                return;
            }
            if (!formData.companyInfo.address.zipcode || 
                !formData.companyInfo.address.address1 || 
                !formData.companyInfo.address.address2) {
                alert('회사 주소를 모두 입력해주세요.');
                return;
            }
        }

        // 유효성 검사 통과 후 다음 페이지로 이동
        navigate('/users/createStep4', { 
            state: {
                memType,
                mailingData,
                smsData,
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                companyInfo: memberType === '도매회원' ? {
                    name: formData.companyInfo.name,
                    businessFile: formData.companyInfo.businessFile,
                    businessNumber: formData.companyInfo.businessNumber,
                    businessType: formData.companyInfo.businessType,
                    address: formData.companyInfo.address
                } : null
            }
        });
    };


    const handleMemberTypeChange = (e) => {
        setMemberType(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCompanyInfoChange = (e) => {
        const { name, value } = e.target;

        // 점(.)을 기준으로 이름을 분리
        const nameParts = name.split('.'); // '.'로 변경
        if (nameParts.length === 2) {
            const [key, subKey] = nameParts;
            setFormData(prev => ({
                ...prev,
                companyInfo: {
                    ...prev.companyInfo,
                    [key]: {
                        ...prev.companyInfo[key],
                        [subKey]: value
                    }
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                companyInfo: {
                    ...prev.companyInfo,
                    [name]: value
                }
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData(prev => ({
            ...prev,
            companyInfo: {
                ...prev.companyInfo,
                businessFile: file
            }
        }));
    };

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    <div className="title-box">
                        <h2>회원가입</h2>
                    </div>
                    <form>
                        <div className="title-box">
                            <h3>기본 정보</h3>
                        </div>
                        <div className="write-box">
                            <ul>
                                <li>
                                    <strong className="form-title">회원유형</strong>
                                    <div className="form-content flex flex-vc">
                                        <div className="check-box mr24">
                                            <input 
                                                type="radio" 
                                                name="memberType" 
                                                id="type01" 
                                                value="일반회원"
                                                checked={memberType === '일반회원'}
                                                onChange={handleMemberTypeChange}
                                            />
                                            <label htmlFor="type01">일반회원</label>
                                        </div>
                                        <div className="check-box">
                                            <input 
                                                type="radio" 
                                                name="memberType" 
                                                id="type02" 
                                                value="도매회원"
                                                checked={memberType === '도매회원'}
                                                onChange={handleMemberTypeChange}
                                            />
                                            <label htmlFor="type02">도매회원</label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">이름</strong>
                                    <div className="form-content">
                                        <div className="input-box">
                                            <input 
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                placeholder="이름 입력"
                                            />
                                        </div>
                                    </div>
                                </li>

                                <li style={{marginBottom:"8px", position: "relative"}}>
                                    <strong className="form-title">휴대폰 번호</strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc">
                                            <div className="input-box flex-1">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    placeholder="휴대폰 번호 입력"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-box w120" style={{
                                        position: "absolute",
                                        top: "37px",
                                        right: "12px",
                                        borderRadius: "2px",
                                        background: "#EEE",
                                        padding: "7px 0px"
                                    }}>
                                        <button type="button" style={{color:"#111", textAlign:"center", fontSize:"12px", fontWeight:"400", lineHeight:"150%"}}>
                                            인증번호 전송
                                        </button>
                                    </div>
                                </li>

                                <li>
                                    <div className="form-content">
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                name="verificationCode"
                                                value={formData.verificationCode}
                                                onChange={handleInputChange}
                                                placeholder="인증번호 입력"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">이메일</strong>
                                    <div className="form-content">
                                        <div className="input-box">
                                            <input 
                                                type="text" 
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="이메일 입력" 
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {memberType === '도매회원' && (
                            <div className="mt24">
                                <div className="title-box">
                                    <h3>사업자 정보</h3>
                                </div>
                                <div className="write-box">
                                    <ul>
                                        <li>
                                            <strong className="form-title">회사명</strong>
                                            <div className="form-content">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="name"
                                                        value={formData.companyInfo.name}
                                                        onChange={handleCompanyInfoChange}
                                                        placeholder="상호 입력" 
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong className="form-title">사업자등록증</strong>
                                            <div className="form-content">
                                                <div className="file-box">
                                                <p style={{color:"#777777"}}>{formData.companyInfo.businessFile?.name || "사업자등록증을 첨부해주세요"}</p>
                                                    <label htmlFor="file">
                                                        첨부파일
                                                        <input 
                                                            type="file" 
                                                            name="file" 
                                                            id="file"
                                                            onChange={handleFileChange}
                                                            placeholder="사업자등록증 첨부" 
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong className="form-title">사업자등록번호</strong>
                                            <div className="form-content">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="businessNumber"
                                                        value={formData.companyInfo.businessNumber}
                                                        onChange={handleCompanyInfoChange}
                                                        placeholder="사업자번호 입력" 
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong className="form-title">업종</strong>
                                            <div className="form-content">
                                                <div className="input-box">
                                                    <input 
                                                        type="text" 
                                                        name="businessType"
                                                        value={formData.companyInfo.businessType}
                                                        onChange={handleCompanyInfoChange}
                                                        placeholder="업종 입력" 
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong className="form-title">회사 주소</strong>
                                            <div className="form-content">
                                                <div className="flex flex-vc">
                                                    <div className="input-box flex-1 mr6">
                                                        <input 
                                                            type="text"
                                                            value={address.zonecode}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="button-box w120">
                                                        <button type="button" className="btn btn-gray f13" onClick={handleOpenPostcode}>우편번호 찾기</button>
                                                    </div>
                                                    {isPostcodeOpen && (
                                                            <div style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1000,
                                                                width: '600px',
                                                                height: '400px',
                                                                background: 'white',
                                                                border: '1px solid #ccc'
                                                            }}>
                                                                <DaumPostcodeEmbed
                                                                    onComplete={handleComplete}
                                                                    onClose={handleClosePostcode}
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="input-box mt8">
                                                    <input 
                                                        type="text" 
                                                        name="address.address1"
                                                        value={address.roadAddress}
                                                        onChange={handleCompanyInfoChange}
                                                    />
                                                </div>
                                                <div className="input-box mt8">
                                                    <input 
                                                        type="text" 
                                                        name="address.address2"
                                                        value={formData.companyInfo.address.address2}
                                                        onChange={handleCompanyInfoChange}
                                                        placeholder="상세주소 입력" 
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className="button-box mt24">
                            <button type="button" onClick={handleNext} className="btn btn-black">다음</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default CreateStep3;
