import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useUserStore from '../../store/userStore';
import { TAX_CREATE_URL, TAX_UPDATE_URL } from '../../utils/urls';
import instance from '../../services/services';

function Create() {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location)
    const { userInfo } = useUserStore();
    const isEdit = location.state?.mode === 'edit';  // 수정 모드 여부
    const taxData = location.state?.taxData;  // 수정할 데이터
    
    const [formData, setFormData] = useState({
        subject: '',
        contents: '',
        upload_images: null,
        order_seq: '',
        order_date: '',
        order_name: '',
        order_price: '',
        order_email: '',
    });
    const [isChecked, setIsChecked] = useState(false);

    // 수정 모드일 경우 기존 데이터 로드
    useEffect(() => {
        if (isEdit && taxData) {
            setFormData({
                subject: taxData.subject || '',
                contents: taxData.contents || '',
                upload_images: null,  // 파일은 초기화
                order_seq: taxData.order_seq || '',
                order_date: taxData.order_date || '',
                order_name: taxData.order_name || '',
                order_price: taxData.order_price || '',
                order_email: taxData.order_email || '',
            });
            setIsChecked(true);  // 수정 시에는 체크박스 자동 체크
        }
    }, [isEdit, taxData]);

    // 입력 필드 변경 핸들러
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 파일 변경 핸들러
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log('Selected file:', {
                name: file.name,
                type: file.type,
                size: file.size
            });
            
            setFormData(prev => ({
                ...prev,
                upload_images: file
            }));
        }
    };

    // 폼 제출 핸들러 수정
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!isChecked) {
            alert('개인정보 수집/이용에 동의해주세요.');
            return;
        }

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'upload_images' && formData[key]) {
                    formDataToSend.append(key, formData[key]);
                } else if (formData[key]) {
                    formDataToSend.append(key, formData[key]);
                }
            });

            // 수정 모드일 경우 b_seq 추가
            if (isEdit) {
                formDataToSend.append('b_seq', taxData.b_seq);
            }

            const response = await instance.post(
                isEdit ? TAX_UPDATE_URL : TAX_CREATE_URL, 
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.data.success) {
                alert(isEdit ? '세금계산서가 수정되었습니다.' : '세금계산서가 등록되었습니다.');
                navigate('/bill');
            }
        } catch (error) {
            console.error(isEdit ? '세금계산서 수정 실패:' : '세금계산서 등록 실패:', error);
            alert(isEdit ? '세금계산서 수정에 실패했습니다.' : '세금계산서 등록에 실패했습니다.');
        }
    };

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>{isEdit ? '세금계산서 수정' : '세금계산서'}</h2>
                        </div>
                        <form onSubmit={handleSubmit} className="board-write-box">
                            <ul>
                                <li>
                                    <strong>아이디</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    className="h40"
                                                    value={userInfo.userid}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>제목</strong>
                                    <div>
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                name="subject"
                                                className="h40"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>내용</strong>
                                    <div>
                                        <div className="textarea-box lg">
                                            <textarea
                                                name="contents"
                                                value={formData.contents}
                                                onChange={handleChange}
                                                required
                                                placeholder={`※ 세금계산서 발행은 제품을 구입한 다음 달 10일까지 가능하며, 이후에는 발행될 수 없습니다.\n※ 사업자등록증을 파일로 첨부하시면 아래의 항목은 입력하실 필요가 없습니��.\n\n상호명:\n대표자:\n사업자등록번호:\n사업장소재지:\n업태:\n종목:`}
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>정보</strong>
                                    <div>
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                name="order_seq"
                                                className="h40"
                                                placeholder="주문번호 입력 (모르실 경우 공란)"
                                                value={formData.order_seq}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                name="order_date"
                                                className="h40"
                                                placeholder="주문일 입력"
                                                value={formData.order_date}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                name="order_name"
                                                className="h40"
                                                placeholder="주문자명 입력"
                                                value={formData.order_name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                name="order_price"
                                                className="h40"
                                                placeholder="결제(입금) 금액"
                                                value={formData.order_price}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="email"
                                                name="order_email"
                                                className="h40"
                                                placeholder="세금계산서를 받을 이메일 입력"
                                                value={formData.order_email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>파일</strong>
                                    <div>
                                        <div className="w700 col-lg-12">
                                            <div className="file-box">
                                                <p>파일 업로드</p>
                                                <label htmlFor="file">
                                                    첨부파일
                                                    <input 
                                                        type="file" 
                                                        name="upload_images" 
                                                        id="file"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="check-box mt20">
                                <input 
                                    type="checkbox" 
                                    name="check" 
                                    id="check"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                />
                                <label htmlFor="check" className="flex-vt">
                                    <p className="fw7 flex-1">
                                        개인정보 수집/이용 동의 (필수)
                                        <small>
                                            목적: 쇼핑몰에서 제공하는 게시판 이용, 일반 게시판에 게시글
                                            등록, 불법, 광고 게시글 등록 방지 <br />
                                            항목: 이름,비밀번호, 이메일, 작성내용, IP 첨부(파일에 개인정모
                                            포함 시에만 수집, 이용) <br />
                                            보유기간: 게시글 삭제 시 까지
                                        </small>
                                    </p>
                                </label>
                            </div>
                            <div className="button-box mt32">
                                <div className="w200 flex-lg-1 mr8">
                                    <Link to="/bill" className="btn btn-white">
                                        취소
                                    </Link>
                                </div>
                                <div className="w200 flex-lg-1" onClick={handleSubmit}>
                                    <Link type="submit" className="btn btn-black">
                                        {isEdit ? '수정' : '등록'}
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Create;
