import React from "react";
import SidebarMenu from "../../components/SidebarMenu";

function Index() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="오시는길" />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>오시는길</h2>
                        </div>
                        <div className="map-box">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10646.72979351772!2d127.02297141388883!3d37.50103760678486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca156488b7b19%3A0x3c3db4afd4e58ba1!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDthYztl6TrnoDroZwgMTI4!5e0!3m2!1sko!2skr!4v1733788411043!5m2!1sko!2skr"
                                width="600"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="지도"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
