import React from 'react';
import SidebarMenu from "../../components/SidebarMenu";

function Index() {
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="회원혜택"/>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>회원혜택</h2>
                        </div>
                        <div className="benefit-box">
                            <ul>
                                <li>
                                    <h3>일반회원 등급별 혜택</h3>
                                    <div className="list">
                                        <dl>
                                            <dd>
                                                <div className="list-head">
                                                    <b>FAMILY</b>
                                                </div>
                                                <div className="list-body">
                                                    <em>F</em>
                                                    <strong>회원가입 한 신규회원</strong>
                                                    <b>적립률</b>
                                                    <p>-</p>
                                                    <b>할인쿠폰</b>
                                                    <p>가입 축하 3,000원 쿠폰</p>
                                                </div>
                                            </dd>
                                            <dd>
                                                <div className="list-head">
                                                    <b>SILVER</b>
                                                </div>
                                                <div className="list-body">
                                                    <em>S</em>
                                                    <strong>1회 이상 구매회원</strong>
                                                    <b>적립률</b>
                                                    <p>1%</p>
                                                    <b>할인쿠폰</b>
                                                    <p>-</p>
                                                </div>
                                            </dd>
                                            <dd>
                                                <div className="list-head">
                                                    <b>GOLD</b>
                                                </div>
                                                <div className="list-body">
                                                    <em>G</em>
                                                    <strong>100만원 이상 구매회원</strong>
                                                    <b>적립률</b>
                                                    <p>2%</p>
                                                    <b>할인쿠폰</b>
                                                    <p>-</p>
                                                </div>
                                            </dd>
                                            <dd>
                                                <div className="list-head">
                                                    <b>VIP</b>
                                                </div>
                                                <div className="list-body">
                                                    <em>G</em>
                                                    <strong>200만원 이상 구매회원</strong>
                                                    <b>적립률</b>
                                                    <p>3%</p>
                                                    <b>할인쿠폰</b>
                                                    <p>-</p>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </li>
                                <li>
                                    <h4>등급 이용안내</h4>
                                    <div className="text">
                                        <dl>
                                            <dd>
                                                <b>1.</b>
                                                <p>
                                                    일반등급은 최근 6개월 기준 누적 구매금액으로 산정하여 매월
                                                    1일에 정산합니다.
                                                </p>
                                            </dd>
                                            <dd>
                                                <b>2.</b>
                                                <p>등급 조건에 도달하지 못한 경우 등급 조정이 발생합니다.</p>
                                            </dd>
                                        </dl>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
