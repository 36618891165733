import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function Create() {
    const navigate = useNavigate(); // useNavigate 훅 사용

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"구매후기"} />
                    <div className="sub-right">
                        <div className="board-write-box">
                            <ul>
                                <li>
                                    <strong>후기상품</strong>
                                    <div>
                                        <div className="thumb-box">
                                            <div className="inner">
                                                <img src="/asset/images/img_item_list_01.png" alt="상품 이미지" />
                                                <div>
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                    <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>평점</strong>
                                    <div>
                                        <div className="score-write-box">
                                            <button type="button" className="btn minus">감소</button>
                                            <div className="star">
                                                <span>별점</span>
                                                <span>별점</span>
                                                <span>별점</span>
                                                <span>별점</span>
                                                <span>별점</span>
                                            </div>
                                            <button type="button" className="btn plus">증가</button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>제목</strong>
                                    <div>
                                        <div className="input-box">
                                            <input type="text" placeholder="제목을 입력해 주세요." className="h40" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>내용</strong>
                                    <div>
                                        <div className="textarea-box lg">
                                            <textarea placeholder="내용을 입력해 주세요."></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>첨부파일</strong>
                                    <div>
                                        <div className="file-image-box">
                                            <ul className="file-list">
                                                <li>
                                                    <label htmlFor="file">
                                                        파일찾기
                                                        <input type="file" name="file" id="file" multiple />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="message-box">
                                            <p className="error">- 파일당 최대 10MB 의 용량 제한이 있습니다.</p>
                                            <p className="ft-lightgray">- 최대 5개까지 등록 가능합니다.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt32">
                            <div className="w200 flex-lg-1 mr8">
                                <Link
                                    to="#"
                                    className="btn btn-white"
                                    onClick={(e) => {
                                        e.preventDefault(); // 기본 동작 방지
                                        navigate(-1); // 뒤로 가기
                                    }}
                                >
                                    취소
                                </Link>
                            </div>
                            <div className="w200 flex-lg-1">
                                <Link to="#" className="btn btn-black">
                                    등록
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Create;
