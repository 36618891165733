import React from 'react';
import { Link, useLocation, Navigate } from "react-router-dom";

function Result() {
    const location = useLocation();
    const { paymentInfo, isPaymentComplete } = location.state || {};

    // 직접 접근 방지
    if (!location.state || !isPaymentComplete) {
        return <Navigate to="/" replace />;
    }

    // paymentInfo에서 필요한 데이터 추출
    const { orderId, amount } = paymentInfo;
    console.log(orderId, amount, '::::paymentInfo');


    return (
        <section>
            <div className="container">
                <div className="title-box mb40 mb-lg-24">
                    <h2>주문/결제</h2>
                </div>
                <div className="item-cart-box order">
                    <div className="cart-left">
                        <div className="cart-head py28 py-lg-0">
                            &nbsp;
                        </div>
                        <div className="cart-body">
                            <ul>
                                <li>
                                    <div className="item">
                                        <div className="inner">
                                            <div className="list-image"
                                                style={{backgroundImage:"url('/asset/images/img_item_list_01.png')"}}></div>
                                            <div className="list-content">
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                    <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small>
                                                </div>
                                                <div className="price">
                                                    <small><strike>10,000</strike>원</small>
                                                    <div>
                                                        <strong>20%</strong>
                                                        <p>8,000<em>원</em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <p>배송비 별도</p>
                                    </div>
                                    <div className="cell">
                                        <p><b>8,000</b>원</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <div className="inner">
                                            <div className="list-image" style={{backgroundImage:"url('/asset/images/img_item_list_01.png')"}}></div>
                                            <div className="list-content">
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                    <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small>
                                                </div>
                                                <div className="price">
                                                    <small><strike>10,000</strike>원</small>
                                                    <div>
                                                        <strong>20%</strong>
                                                        <p>8,000<em>원</em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <p>배송비 별도</p>
                                    </div>
                                    <div className="cell">
                                        <p><b>8,000</b>원</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cart-middle mt-lg-24">
                        <div className="write-list-box space">
                            <h3>배송지 정보</h3>
                            <ul>
                                <li>
                                    <strong className="form-title">받으시는 분</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>강승혜</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">핸드폰 번호</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>010-1234-1234</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 주소</strong>
                                    <div className="form-content">
                                        <p>[06068] 서울 강남구 삼성로133길 14 3층 302호(청담동, 현대빌딩)</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 메세지</strong>
                                    <div className="form-content">
                                        <p>배송 전에 연락주세요.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="write-list-box space mt40 mt-lg-24">
                            <h3 className="flex flex-vc">
                                결제 정보
                                <div className="is-m ml8">
                                    <div className="button-box">
                                        <Link to="" className="btn btn-white h20 px6 f10">거래명세서</Link>
                                    </div>
                                </div>
                            </h3>
                            <ul>
                                <li>
                                    <strong className="form-title">주문번호</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>2024042016493938479</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">주문일자</strong>
                                    <div className="form-content flex flex-vc">
                                        <p>2024.04.20</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제일시</strong>
                                    <div className="form-content">
                                        <p>주문접수</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제수단</strong>
                                    <div className="form-content">
                                        <p>무통장입금 (입금자명: 강승혜)</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">입금계좌</strong>
                                    <div className="form-content">
                                        <p>농협 123-45678-13924 예금주:퍼스트몰</p>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">결제금액</strong>
                                    <div className="form-content">
                                        <p>14,000원</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cart-right is-pc">
                        <ul>
                            <li>
                                <p>상품금액</p>
                                <p>24,000<em>원</em></p>
                            </li>
                            <li>
                                <p>배송비</p>
                                <p>+3,000<em>원</em></p>
                            </li>
                            <li>
                                <p>할인금액</p>
                                <p>-12,000<em>원</em></p>
                            </li>
                            <li className="border">
                                <p>포인트 사용</p>
                                <p>-1,000<em>원</em></p>
                            </li>
                            <li>
                                <b>총 결제금액</b>
                                <b>14,000<em>원</em></b>
                            </li>
                            <li>
                                <small>적립예정 포인트</small>
                                <small>3,995<em>p</em></small>
                            </li>
                        </ul>
                        <div className="button-box">
                            <div className="col-12 flex"><Link to="" className="btn btn-active f13">주문내역</Link></div>
                            <div className="col-12 flex mt8"><Link to="/" className="btn btn-white f13">쇼핑 계속하기</Link></div>
                        </div>
                    </div>
                    <div className="cart-button">
                        <div className="button-box">
                            <Link to="/" className="btn btn-white f13 mr8">쇼핑 계속하기</Link>
                            <Link to="" className="btn btn-active f13">결제하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Result;
