import {jwtDecode} from "jwt-decode";


export const decodeToken = (token) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error("JWT 토큰 디코딩 오류:", error);
        return null;
    }
};

export const getRemainingTime = token => {
    const exp = token.exp * 1000; // 만료 시간을 밀리초로 변환
    const now = Date.now();
    return exp - now;
};
