import React from "react";
import { Link } from "react-router-dom";

function MypageSidebarMenu({ activeItem }) {
    return (
        <div className="sub-left">
            <h2>나의 쇼핑정보</h2>
            <ul>
                <li className={activeItem === "주문/배송 관리" ? "active" : ""}>
                    <Link to="/" className="link">
                        주문/배송 관리
                    </Link>
                </li>
                <li className={activeItem === "취소/교환/반품 관리" ? "active" : ""}>
                    <Link to="/" className="link">
                        취소/교환/반품 관리
                    </Link>
                </li>
                <li className={activeItem === "관심상품" ? "active" : ""}>
                    <Link to="/" className="link">
                        관심상품
                    </Link>
                </li>
                <li className={activeItem === "배송주소록 관리" ? "active" : ""}>
                    <Link to="/" className="link">
                        배송주소록 관리
                    </Link>
                </li>
            </ul>
            <h2 className="mt32">나의 계정설정</h2>
            <ul>
                <li className={activeItem === "회원정보 수정" ? "active" : ""}>
                    <Link to="/" className="link">
                        회원정보 수정
                    </Link>
                </li>
                <li className={activeItem === "쿠폰" ? "active" : ""}>
                    <Link to="/" className="link">
                        쿠폰
                    </Link>
                </li>
                <li className={activeItem === "포인트" ? "active" : ""}>
                    <Link to="/" className="link">
                        포인트
                    </Link>
                </li>
            </ul>
            <h2 className="mt32">나의 활동정보</h2>
            <ul>
                <li className={activeItem === "세금계산서 내역" ? "active" : ""}>
                    <Link to="/mypage/bill" className="link">
                        세금계산서 내역
                    </Link>
                </li>
                <li className={activeItem === "구매후기" ? "active" : ""}>
                    <Link to="/mypage/review/able" className="link">
                        구매후기
                    </Link>
                </li>
                <li className={activeItem === "상품문의" ? "active" : ""}>
                    <Link to="/mypage/QA-product" className="link">
                        상품문의
                    </Link>
                </li>
                <li className={activeItem === "1:1문의" ? "active" : ""}>
                    <Link to="/mypage/qa" className="link">
                        1:1문의
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default MypageSidebarMenu;
