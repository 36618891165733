import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';


function Index() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"상품문의"} />
                    <div className="sub-right">
                        <div className="title-box bb2 pb14 mb0">
                            <h2 className="tl f18">상품문의(24)</h2>
                        </div>
                        <div className="list-none-box">
                            <p>등록된 문의글이 없습니다.</p>
                        </div>
                        <div className="qa-list-box">
                            <ul>
                                <li>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                                <div className="sticker-box">
                                                    <span className="gray">답변대기</span>
                                                </div>
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                </div>
                                                <div className="content">
                                                    <b>입금문의</b>
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <time>2024.09.26</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <Link to="#" className="btn btn-white sm h32">
                                                    삭제
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                                <div className="sticker-box">
                                                    <span className="red">답변완료</span>
                                                </div>
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                </div>
                                                <div className="content">
                                                    <b>입금문의</b>
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <time>2024.09.26</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <Link to="#" className="btn btn-white sm h32">
                                                    삭제
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-a">
                                        <b>답변 내용</b>
                                        <p>
                                            안녕하세요 고객님 오톡입니다. <br />
                                            <br />
                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나
                                            잎이 많은 채소에도 안전하게 사용하실 수 있습니다.
                                            <br />
                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여
                                            농약이나 오염물질을 효과적으로 제거해줍니다.
                                            <br />
                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이
                                            남지 않도록 깔끔하게 헹구어져 안심하고 드실 수 있습니다.
                                            <br />
                                            <br />
                                            감사합니다.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-q">
                                        <div className="list-left">
                                            <button type="button" className="inner">
                                                <div className="sticker-box">
                                                    <span className="red">답변완료</span>
                                                </div>
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                </div>
                                                <div className="content">
                                                    <b>입금문의</b>
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <time>2024.09.26</time>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <Link to="#" className="btn btn-white sm h32">
                                                    삭제
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-a">
                                        <b>답변 내용</b>
                                        <p>
                                            안녕하세요 고객님 오톡입니다. <br />
                                            <br />
                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나
                                            잎이 많은 채소에도 안전하게 사용하실 수 있습니다.
                                            <br />
                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여
                                            농약이나 오염물질을 효과적으로 제거해줍니다.
                                            <br />
                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이
                                            남지 않도록 깔끔하게 헹구어져 안심하고 드실 수 있습니다.
                                            <br />
                                            <br />
                                            감사합니다.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
