import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import Swiper from "swiper";
import useUserStore from "../../store/userStore";
import { formatLocalString } from '../../utils/validation';
import { createInquiryList, getInquiryList, getInquiryDetailList, getInquiryTypeList } from '../../services/Border/border';
import { PRODUCT_DETAIL_INFO_URL, PRODUCT_REVIEW_URL, CART_ADD_URL } from '../../utils/urls';
import instance from '../../services/services';


import PopupCarts from '../../components/popupComponents/PopupCarts';
import PopupPhotoReview from '../../components/popupComponents/PopupPhotoReview';
import PopupInquiry from '../../components/popupComponents/PopupInquiry.js';

function Show() {
    const navigate = useNavigate();
    const { userInfo } = useUserStore();
    const location = useLocation();
    const [productDetail, setProductDetail] = useState(null);
    console.log(productDetail, ':::productDetail');
    const [inquiryContent, setInquiryContent] = useState('');
    const [isSecret, setIsSecret] = useState(false);
    const [inquiryType, setInquiryType] = useState('goods');
    const [inquiryTypeList, setInquiryTypeList] = useState([]);
    const [inquiryData, setInquiryData] = useState([]);
    const [selectedInquiryDetail, setSelectedInquiryDetail] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0); // 초기 가격
    const [totalPrice, setTotalPrice] = useState(0); // 총 구매금액
    const [deliveryPrice] = useState(3000); // 택배 금액

    // 상품 상세 데이터 로딩 후 price 설정
    useEffect(() => {
        if (productDetail?.price) {
            setPrice(productDetail.price); // 상품 가격 업데이트
        }
    }, [productDetail]); // productDetail이 변경될 때마다 실행

    // 수량 변경 시 totalPrice 갱신
    useEffect(() => {
        if (price) {
            // 수량에 따른 금액 계산
            const newTotalPrice = price * quantity;

            // 총 금액에 배송비는 한 번만 추가
            setTotalPrice(newTotalPrice + deliveryPrice);
        }
    }, [price, quantity, deliveryPrice]); // price, quantity, deliveryPrice가 변경될 때마다 실행


    // 수량 증가 함수
    const handleIncrease = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        setTotalPrice(price * newQuantity);
    };

    // 수량 감소 함수
    const handleDecrease = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            setTotalPrice(price * newQuantity);
        }
    };

    // 팝업 열기 함수 수정
    // const popupOpen = (type) => {
    //     const box = document.querySelector(`.popup-box > .box[data-name="${type}"]`);
        
    //     document.documentElement.classList.add("fixed");
    //     document.body.classList.add("fixed");
    //     document.querySelector(".popup-box").classList.add("fixed");

    //     if (box) box.classList.add("active");

    //     // 팝업 열 때 상품 타입 리스트 로드
    //     if (type === 'popup03') {
    //         loadInquiryTypeList();
    //     }

    //     // 히스토리 상태 추가
    //     window.history.pushState({ popup: true }, "");
    // };

    const popupClose = () => {
        document.documentElement.classList.remove("fixed");
        document.body.classList.remove("fixed");
        document.querySelector(".popup-box").classList.remove("fixed");
        document.querySelectorAll(".popup-box .box").forEach(box => box.classList.remove("active"));

        // 뒤로가기를 통한 팝업 닫기라면 히스토리 상태를 되돌림
        if (window.history.state?.popup) window.history.back();
    };

    useEffect(() => {
        const handlePopState = () => popupClose();
        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, []);

    
    // 상세페이지 데이터
    useEffect(() => {
        const fetchProductDetail = async (goodsSeq) => {
            try {
                const response = await instance.post(PRODUCT_DETAIL_INFO_URL, {
                    goods_seq: goodsSeq,
                    member_seq: userInfo.member_seq
                });
                setProductDetail(response.data.data);
            } catch (error) {
                console.error('상품 상세 정보 로딩 실패:', error);
            }
        };

        const storedGoodsSeq = localStorage.getItem('goods_seq');
        const currentGoodsSeq = location.state?.goods_seq || storedGoodsSeq;

        if (currentGoodsSeq) {
            localStorage.setItem('goods_seq', currentGoodsSeq);
            fetchProductDetail(currentGoodsSeq);
        }
    }, [location.state?.goods_seq, userInfo.member_seq]);

    useEffect(() => {
        var swiper1 = new Swiper(".big-swiper .swiper-container", {
            spaceBetween: 10,
            speed: 1000,
            touchRatio: 0,
        });

        new Swiper(".thumb-swiper .swiper-container", {
            slidesPerView: 6,
            spaceBetween: 8,
            observer: true,
            observeParents: true,
            watchSlidesProgress: true,
        });

        new Swiper(".photo-view .swiper-container", {
            spaceBetween: 10,
            speed: 1000,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: ".photo-view .swiper-button-next",
                prevEl: ".photo-view .swiper-button-prev",
            },
        });

        document.querySelectorAll('.thumb-swiper button').forEach(function(button) {
            button.addEventListener('click', function() {
                var parents = this.closest('.swiper-slide');
                var index = Array.from(parents.parentElement.children).indexOf(parents);

                parents.classList.add('active');
                Array.from(parents.parentElement.children).forEach(function(sibling) {
                    if (sibling !== parents) {
                        sibling.classList.remove('active');
                    }
                });

                swiper1.slideTo(index);
            });
        });
    }, []);

    // 상품문의 등록
    const handleCreateInquiry = async (event) => {
        event.preventDefault();
        const data = {
            boardid: "goods",
            board_type: inquiryType,
            subject: "테스트 제목임 하드코딩", // 제목은 빈 문자열로
            contents: inquiryContent, // textarea 내용
            secret_flag: isSecret ? 'Y' : 'N' // 비밀글 체크 여부
        };
        
        try {
            await createInquiryList(data);
            alert('상품문의 등록완료입니다.');
            popupClose();
            // 입력 필드 초기화
            setInquiryContent('');
            setIsSecret(false);
        } catch (error) {
            console.error('상품문의 등록 실패:', error);
            alert('상품문의 등록에 실패했습니다.');
        }
    };

    useEffect(() => {
        let isMounted = true; // 마운트 상태를 추적하는 플래그
    
        const handleGetInquiryList = async () => {
            try {
                const response = await getInquiryList({
                    boardid: "goods",
                    member_chk: "N",
                    offset: 1,
                    board_type: "goods",
                });
                const inquiryList = response.data.data.data;
                
                // 컴포넌트가 여전히 마운트 상태인 경우에만 상태 업데이트
                if (isMounted) {
                    setInquiryData(inquiryList);
                }
            } catch (error) {
                // 컴포넌트가 여전히 마운트 상태인 경우에만 에러 처리
                if (isMounted) {
                    console.error('상품문의 리스트 조회 실패:', error);
                    alert('상품문의 리스트 조회에 실패했습니다.');
                }
            }
        };
    
        handleGetInquiryList();
    
        // 클린업 함수
        return () => {
            isMounted = false; // 컴포넌트 언마운트 시 플래그 설정
        };
    }, []); // 빈 의존성 배열 유지

    // 문의 상세 정보 조회 함수
    const fetchInquiryDetail = async (b_seq) => {
        // 이미 로드된 상세 정보가 있다면 다시 로드하지 않음
        if (selectedInquiryDetail[b_seq]) return;

        try {
            const response = await getInquiryDetailList({ b_seq });
            setSelectedInquiryDetail(prev => ({
                ...prev,
                [b_seq]: response.data.data
            }));
        } catch (error) {
            console.error('문의 상세 정보 조회 실패:', error);
        }
    };

    // 상품 타입 리스트 불러오기 함수
    const loadInquiryTypeList = async () => {
        try {
            const response = await getInquiryTypeList({ board_type: "goods_type" });
            console.log(response.data.data, ":::response");
            
            // 데이터 구조에 맞게 변환
            const transformedTypeList = response.data.data.map(item => ({
                code: item.codecd,
                name: item.value
            }));
            
            setInquiryTypeList(transformedTypeList);
        } catch (error) {
            console.error('상품 타입 리스트 조회 실패:', error);
        }
    };

 // 좋아요 처리 함수 수정
const handleLikeClick = async (event) => {
    event.preventDefault();
    
    try {
        const goodsSeq = location.state?.goods_seq || localStorage.getItem('goods_seq');
        
        if (!goodsSeq) {
            alert('상품 정보를 찾을 수 없습니다.');
            return;
        }

        const response = await instance.post(PRODUCT_REVIEW_URL, {
            member_seq: userInfo.member_seq,
            goods_seq: goodsSeq,
        });

        if (response.data.success) {
            setProductDetail(prevDetail => ({
                ...prevDetail,
                liked: !prevDetail.liked,
                goods_like_seq: prevDetail.goods_like_seq ? null : response.data.data?.goods_like_seq,
                like_count: prevDetail.goods_like_seq ? 
                    (prevDetail.like_count - 1) : 
                    (prevDetail.like_count + 1)
            }));
        }
    } catch (error) {
        console.error("좋아요 처리 오류:", error);
    }
};
    // 장바구니 추가 함수 수정
    const handleCartAdd = async () => {
        try {
            // location.state가 없을 경우 localStorage에서 goods_seq 가져오기
            const goodsSeq = location.state?.goods_seq || localStorage.getItem('goods_seq');
            
            if (!goodsSeq) {
                alert('상품 정보를 찾을 수 없습니다.');
                return;
            }

            const response = await instance.post(CART_ADD_URL, {
                goods_seq: goodsSeq,
                ea: quantity,
            });

            if (response.data.success) {
                setIsPopupCarts(true)
            } else {
                alert('장바구니 추가에 실패했습니다.');
            }
        } catch (error) {
            console.error("장바구니 추가 오류:", error);
            alert('장바구니 추가 중 오류가 발생했습니다.');
        }
    };


    const handlePurchase = () => {
        if (!productDetail) {
            alert('상품 정보를 불러오는 중입니다.');
            return;
        }
        const orderData = {
            cartList: {
                data: [{
                    goods_seq: productDetail.goods_seq,
                    goods_name: productDetail.goods_name,
                    goods_image: productDetail.goods_image[0],
                    consumer_price: productDetail.consumer_price,
                    price: productDetail.price,
                    sale_percent: productDetail.sale_percent,
                    quantity: quantity,
                    supply_price: productDetail.supply_price,
                    margin_rate: productDetail.margin_rate,
                    goods_status: productDetail.goods_status,
                    banner_image: productDetail.banner_image,
                    main_image: productDetail.main_image,
                    goods_overview: productDetail.goods_overview,
                    goods_guide: productDetail.goods_guide,
                    delivery_recipient_address_street: productDetail.delivery_recipient_address_street,
                    ea : quantity
                }]
            },
            payInfo: {
                settlePrice: Number(productDetail.price) * quantity,
                delivery_price: deliveryPrice,
                sumSalePrice: Number(productDetail.consumer_price - productDetail.price) * quantity,
                sumPointUse: 0,
                reserve: 0
            }
        };
    
        navigate('/orders/create', {
            state: orderData
        });
    };
        

    // 장바구니 팝업
    const [isPopupCarts, setIsPopupCarts] = useState(false);

    // 포토리뷰
    const [isPopupPhotoReview, setIsPopupPhotoReview] = useState(false)

    // 문의 팝업
    const [isPopupInquiry, setIsPopupInquiry] = useState(false)

    return (
        <section className="overflow">
            <div className="container">
                <div className="item-view-box">
                    <div className="view-head">
                        <div className="head-image">
                            <div className="big-swiper">
                                <div className="sticker-box">
                                    <span className="red">추천</span>
                                    <span className="orange">NEW</span>
                                </div>
                                <div className="swiper-container">
                                    <ul className="swiper-wrapper">
                                        {/* 메인 이미지 영역 - 클릭했을 때 보여질 영역 */}
                                        {[...Array(8)].map((_, index) => (
                                            <li key={index} className="swiper-slide"
                                            style={{backgroundImage: `url(${productDetail?.goods_image?.[index % productDetail.goods_image.length]})`}}></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="thumb-swiper">
                                <div className="swiper-container">
                                    <ul className="swiper-wrapper">
                                        {/* 썸네일 영역 - 클릭하는 영역 */}
                                        {[...Array(8)].map((_, index) => (
                                            <li key={index} className={`swiper-slide ${index === 0 ? 'active' : ''}`}>
                                                <button type="button"
                                                        style={{backgroundImage: `url(${productDetail?.goods_image?.[index % productDetail.goods_image.length]})`}}></button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="head-content">
                            <div className="subject">
                                <small>O.tok <button className="share">공유하기</button></small>
                                <strong>{productDetail?.goods_name}</strong>
                            </div>
                            <div className="price">
                                <small>
                                    <strike>{productDetail?.consumer_price ? formatLocalString(productDetail.consumer_price) : '0'}</strike>원
                                </small>
                                <div>
                                    <strong>{productDetail?.sale_percent}%</strong>
                                    <p>{productDetail?.price ? formatLocalString(productDetail.price) : '0'}<em>원</em></p>
                                </div>
                            </div>
                            <div className="info">
                                <dl>
                                    <dd>
                                        <b>배송방법</b>
                                        <p>택배</p>
                                    </dd>
                                    <dd>
                                        <b>배송비</b>
                                        <p>{formatLocalString(deliveryPrice)}원</p>
                                    </dd>
                                </dl>
                            </div>

                            {/* <div className="option">
                                <h3>상품옵션</h3>
                                <div className="select-box">
                                    <select>
                                        <option>[필수] 옵션을 선택해주세요.</option>
                                    </select>
                                </div>
                                <div className="select-box mt8">
                                    <select>
                                        <option>[필수] 옵션을 선택해주세요.</option>
                                    </select>
                                </div>
                            </div> */}

                            
                            <div className="list">
                                <ul>
                                    <li>
                                        <p>[O.Tok]{productDetail?.goods_name}</p>
                                        <div className="list-left">
                                            <div className="quantity-box">
                                                <button 
                                                    type="button" 
                                                    className="minus" 
                                                    onClick={handleDecrease}
                                                >
                                                    감소
                                                </button>
                                                <input 
                                                    type="text" 
                                                    value={quantity} 
                                                    readOnly
                                                />
                                                <button 
                                                    type="button" 
                                                    className="plus" 
                                                    onClick={handleIncrease}
                                                >
                                                    증가
                                                </button>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <strong>
                                                {formatLocalString(totalPrice)}
                                                <em>원</em>
                                            </strong>
                                            <button type="button" className="close">삭제</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="total">
                                <strong>총 상품 금액</strong>
                                <div>
                                    <small>총 수량 {quantity}개</small>
                                    <b>{formatLocalString(totalPrice)}</b>
                                    <em>원</em>
                                </div>
                            </div>
                            <div className="button">
                                <div className="button-box">
                                    <button 
                                        type="button" 
                                        className={`like ${(productDetail?.liked || productDetail?.goods_like_seq) ? 'active' : ''}`} 
                                        onClick={handleLikeClick}
                                    >
                                        <p>{Number(productDetail?.like_count || 0)}</p>
                                    </button>
                                    <button onClick= {handleCartAdd} className="btn btn-gray mr8">장바구니</button>
                                    <button className="btn btn-active" onClick={handlePurchase}>구매하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="view-banner">
                        <img alt='' src={productDetail?.banner_image}/>
                    </div>
                    <div className="view-tab">
                        <div className="tab-container">
                            <div className="tab-box">
                                <ul>
                                    <li className="active">
                                        <button type="button">상세정보</button>
                                    </li>
                                    <li>
                                        <button type="button">구매안내</button>
                                    </li>
                                    <li>
                                        <button type="button">구매후기<em>4</em></button>
                                    </li>
                                    <li>
                                        <button type="button">상품문의<em>{inquiryData.length}</em></button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content-box">
                                <div className="content active">
                                    <div className="youtube-box">
                                        <iframe width="1236" height="695" src="https://www.youtube.com/embed/qp5h6Kfm9ZM"
                                                title="Michael Bublé - It&#39;s Beginning To Look A Lot Like Christmas [1 Hour]"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="guide-box">
                                        <dl>
                                            <dt>상품결제정보</dt>
                                            <dd><p>고액결제의 경우 안전을 위해 카드사에서 확인전화를 드릴 수도 있습니다. 확인과정에서 도난 카드의 사용이나 타인 명의의 주문등
                                                정상적인 주문이 아니라고 판단될 경우 임의로 주문을 보류 또는 취소할 수 있습니다.</p></dd>
                                            <dd><p>무통장 입금은 상품 구매 대금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은 가까운 은행에서 직접 입금하시면 됩니다.</p></dd>
                                            <dd><p>주문시 입력한 입금자명과 실제입금자의 성명이 반드시 일치하여야 하며, 7일 이내로 입금을 하셔야 하며 입금되지 않은 주문은
                                                자동취소 됩니다.</p></dd>
                                        </dl>
                                        <dl>
                                            <dt>배송안내</dt>
                                            <dd><p>배송 방법 : 택배</p></dd>
                                            <dd><p>배송 지역 : 전국지역</p></dd>
                                            <dd><p>배송 비용 : 2,500원</p></dd>
                                            <dd><p>배송 기간 : 3일 ~ 7일</p></dd>
                                            <dd><p>배송 안내 : - 산간벽지나 도서지방은 별도의 추가금액을 지불하셔야 하는 경우가 있습니다.</p></dd>
                                            <dd><p>고객님께서 주문하신 상품은 입금 확인후 배송해 드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연될 수
                                                있습니다.</p></dd>
                                        </dl>
                                        <dl>
                                            <dt>교환/반품</dt>
                                            <dd><b>교환 및 반품 주소</b></dd>
                                            <dd><p>-</p></dd>

                                            <dd className="mt24"><b>교환 및 반품이 가능한 경우</b></dd>
                                            <dd>
                                                <em>-</em>
                                                <p>계약내용에 관한 서면을 받은 날부터 7일. 단, 그 서면을 받은 때보다 재화등의 공급이 늦게 이루어진 경우에는 재화등을
                                                    공급받거나 재화등의 공급이 시작된 날부터 7일 이내</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>공급받으신 상품 및 용역의 내용이 표시.광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날 부터
                                                    3월이내, 그사실을 알게 된 날 또는 알 수 있었��� 날부터 30일이내</p>
                                            </dd>

                                            <dd className="mt24"><b>교환 및 반품이 불가능한 경우</b></dd>
                                            <dd>
                                                <em>-</em>
                                                <p>이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한
                                                    경우에는 청약철회를 할 수 있습니다)</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>복제가 가능한 재화등의 포장을 훼손한 경우</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>개별 주문 생산되는 재화 등 청약철회시 판매자에게 회복할 수 없는 피해가 예상되어 소비자의 사전 동의를 얻은 경우</p>
                                            </dd>
                                            <dd>
                                                <em>-</em>
                                                <p>디지털 콘텐츠의 제공이 개시된 경우, (다만, 가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우 제공이 개시되지 아니한
                                                    부분은 청약철회를 할 수 있습니다.)</p>
                                            </dd>
                                            <dd className="mt24">
                                                <em>※</em>
                                                <p>고객님의 마음이 바뀌어 교환, 반품을 하실 경우 상품반송 비용�� 고객님께서 부담하셔야 합니다. (색상 교환, 사이즈 교환 등
                                                    포함)</p>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="item-review-box">
                                        <h3>구매후기(0)</h3>
                                        <div className="review-none">
                                            <p>등록된 후기글이 없습니다.</p>
                                        </div>
                                        <div className="review-image">
                                            <ul>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_01.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_02.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_03.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_04.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_05.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_06.png')"}}></button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_07.png')"}}></button>
                                                </li>
                                                <li className="more">
                                                    <button type="button" onClick={() => setIsPopupPhotoReview(true)}
                                                            style={{backgroundImage:"url('/asset/images/img_item_review_08.png')"}}
                                                            className="list"><p>전체보기</p></button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="review-content">
                                            <ul>
                                                <li>
                                                    <div className="content-left">
                                                        <div>
                                                            <div className="score-box">
                                                                <span className="active">별점 1</span>
                                                                <span className="active">별점 2</span>
                                                                <span className="active">별점 3</span>
                                                                <span>별점 4</span>
                                                                <span>별점 5</span>
                                                            </div>
                                                            <b>cak******</b>
                                                        </div>
                                                        <b>[O.Tok] 국내최초 천연 산호칼슘 과일야채 세정제</b>
                                                        <p>천연 성분이라 안심하고 사용할 수 있어서 좋아요. 과일이 정말 깨끗하게 씻겨져서 매번 만족합니다.</p>
                                                    </div>
                                                    <div className="content-right">
                                                        <time>2024.09.26</time>
                                                        <img alt=''  src="/asset/images/img_item_review_01.png" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content-left">
                                                        <div>
                                                            <div className="score-box">
                                                                <span className="active">별점 1</span>
                                                                <span className="active">별점 2</span>
                                                                <span className="active">별점 3</span>
                                                                <span>별점 4</span>
                                                                <span>별점 5</span>
                                                            </div>
                                                            <b>cak******</b>
                                                        </div>
                                                        <b>[O.Tok] 국내최초 천연 산호칼슘 과일야채 세정제</b>
                                                        <p>천연 성분이라 안심하고 사용할 수 있어서 좋아요. 과일이 정말 깨끗하게 씻겨져서 매번 만족합니다.</p>
                                                    </div>
                                                    <div className="content-right">
                                                        <time>2024.09.26</time>
                                                        <img alt=''  src="/asset/images/img_item_review_01.png" />
                                                    </div>
                                                </li>
                                                <li className="reply">
                                                    <div className="content-left">
                                                        <div>
                                                            <div className="score-box">
                                                                <span className="active">별점 1</span>
                                                                <span className="active">별점 2</span>
                                                                <span className="active">별점 3</span>
                                                                <span>별점 4</span>
                                                                <span>별점 5</span>
                                                            </div>
                                                            <b>cak******</b>
                                                        </div>
                                                        <b>[O.Tok] 국내최초 천연 산호칼슘 과일야채 세정제</b>
                                                        <p>
                                                            평소 농약이나 화학물질에 민감한 편인데, 이 천연 과일세정제를 사용하고 나서는 걱정 없이 과일을 먹을 수 있게
                                                            되었어요.
                                                            씻고 나면 과일 표면이 매끈하고 깨끗해져서 더 신선한 느낌이 들고, 천연 성분이라 손에 닿아도 안전하다는 점이
                                                            좋습니다.
                                                            이 세정제를 사용하면서 과일을 더 자주 먹게 된 것 같아요. 씻는 게 귀찮지 않고 간편하니까 금방금방 준비할 수
                                                            있거든요.
                                                            베이킹소다나 식초로 씻어도 항상 뭔가 부족하다고 느꼈는데, 천연 과일세정제를 쓰고 나서는 그 걱정이 사라졌어요.
                                                        </p>
                                                    </div>
                                                    <div className="content-right">
                                                        <time>2024.09.26</time>
                                                        <img alt=''  src="/asset/images/img_item_review_01.png" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content-left">
                                                        <div>
                                                            <div className="score-box">
                                                                <span className="active">별점 1</span>
                                                                <span className="active">별점 2</span>
                                                                <span className="active">별점 3</span>
                                                                <span>별점 4</span>
                                                                <span>별점 5</span>
                                                            </div>
                                                            <b>cak******</b>
                                                        </div>
                                                        <b>[O.Tok] 국내최초 천연 산호칼슘 과일야채 세정제</b>
                                                        <p>천연 성분이라 안심하고 사용할 수 있어서 좋아요. 과일이 정말 깨끗하게 씻겨져서 매번 만족합니다.</p>
                                                    </div>
                                                    <div className="content-right">
                                                        <time>2024.09.26</time>
                                                        <img alt=''  src="/asset/images/img_item_review_01.png" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="paging-box">
                                        <ul>
                                            <li className="prev"><Link to="">이전</Link></li>
                                            <li className="active"><Link to="">1</Link></li>
                                            <li><Link to="">2</Link></li>
                                            <li className="next"><Link to="">다음</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="item-qa-box">
                                        <h3>
                                            상품문의({inquiryData.length})
                                            <div className="button-box">
                                                <button type="button" onClick={() => setIsPopupInquiry(true)}
                                                        className="btn btn-black h32 px16 f12">문의하기
                                                </button>
                                            </div>
                                        </h3>
                                        {inquiryData.length > 0 ? (
                                            <div className="qa-list">
                                                <ul>
                                                    {inquiryData.map((inquiry) => (
                                                        <React.Fragment key={inquiry.b_seq}>
                                                            <li 
                                                                onClick={() => fetchInquiryDetail(inquiry.b_seq)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <div className="list-left">
                                                                    <strong>
                                                                        {inquiry.goods_type_name}
                                                                    </strong>
                                                                    {inquiry.secret_flag === 'Y' ? (
                                                                        <p className="secret">비밀글입니다.</p>
                                                                    ) : (
                                                                        <p>{inquiry.contents}</p>
                                                                    )}
                                                                    <dl>
                                                                        <dd>{inquiry.regist_date?.split(' ')[0]}</dd>
                                                                        <dd>{inquiry.userid.slice(0, 3)}{'*'.repeat(5)}</dd>
                                                                    </dl>
                                                                </div>
                                                                <div className="list-right">
                                                                    <span className={inquiry.detail?.data?.re_contents ? 'active' : ''}>
                                                                        {inquiry.detail?.data?.re_contents ? '답변완료' : '답변대기'}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            {/* 답변완료시 */}
                                                            {selectedInquiryDetail[inquiry.b_seq]?.re_contents && (
                                                                <li className="reply">
                                                                    <div className="list-left">
                                                                        <strong>답변 내용</strong>
                                                                        <p>{selectedInquiryDetail[inquiry.b_seq].re_contents}</p>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="qa-none">
                                                <p>등록된 문의글이 없습니다.</p>
                                            </div>
                                        )}


                                        
                                        {/* <div className="qa-list">
                                            <ul>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p className="secret">비밀글입니다.</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span>답변대기</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p>세정제의 천연 성분이 과일의 맛이나 향에 영향을 주지 않나요?</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span>답변대기</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="list-left">
                                                        <strong>입금문의</strong>
                                                        <p>세정제의 천연 성분이 과일의 맛이나 향에 영향을 주지 않나요?</p>
                                                        <dl>
                                                            <dd>2024.09.26</dd>
                                                            <dd>cak******</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="list-right">
                                                        <span className="active">답변완료</span>
                                                    </div>
                                                </li>
                                                <li className="reply">
                                                    <div className="list-left">
                                                        <strong>답변 내용</strong>
                                                        <p>
                                                            안녕하세요 고객님 오톡입니다.<br/><br/>
                                                            네, 천연 산호칼슘 과일야채 세정제는 베리류와 같은 작은 과일이나 잎이 많은 채소에도 안전하게 사용하실 수
                                                            있습니다. <br/>
                                                            산호칼슘 성분이 과일과 채소의 표면을 깨끗하게 세정해주어 잔여 농약이나 오염물질을 효과적으로
                                                            제거해줍니다. <br/>
                                                            세정 후에도 과일과 채소의 본연의 신선함을 유지하면서 잔여물이 남지 않도록 깔끔하게 헹구어져 안심하고 드실 수
                                                            있습니다.<br/><br/>
                                                            감사합니다.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="paging-box">
                                        <ul>
                                            <li className="prev"><Link to="">이전</Link></li>
                                            <li className="active"><Link to="">1</Link></li>
                                            <li><Link to="">2</Link></li>
                                            <li className="next"><Link to="">다음</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="popup-box">
                    <div className="box sm pt0 px0 pb40" data-name="popup01">
                        <div className="popup-head">
                            <button type="button" className="close" onClick={() => popupClose()}>닫기</button>
                        </div>
                        <div className="popup-body">
                            <div className="text-box">
                                <p className="tc">상품이 장바구니에 담겼습니다.</p>
                            </div>
                            <div className="button-box mt32">
                                <div className="w135">
                                    <Link to="/carts" className="btn btn-white f13">장바구니 바로가기</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <PopupCarts isPopup={isPopupCarts} setIsPopup={setIsPopupCarts} />
                
                <PopupPhotoReview isPopup={isPopupPhotoReview} setIsPopup={setIsPopupPhotoReview} />

                <PopupInquiry 
                    isPopup={isPopupInquiry} 
                    setIsPopup={setIsPopupInquiry} 
                    loadInquiryTypeList={loadInquiryTypeList}
                    userInfo={userInfo}
                    isSecret={isSecret}
                    setIsSecret={setIsSecret}
                    inquiryType={inquiryType}
                    setInquiryType={setInquiryType}
                    inquiryTypeList={inquiryTypeList}
                    inquiryContent={inquiryContent}
                    setInquiryContent={setInquiryContent}
                    handleCreateInquiry={handleCreateInquiry}
                />
            </div>
        </section>
    )
}

export default Show;
