import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useUserStore from '../../store/userStore';

// public 폴더의 이미지는 절대 경로로 참조
const loginimg = '/asset/images/loginimg.png';
const logoutimg = '/asset/images/logoutimg.png';

function Header() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout, memberTokens } = useUserStore();
  
  useEffect(() => {
    setIsLoggedIn(!!memberTokens);
  }, [memberTokens]);
  
  const handleAuthClick = () => {
    if (isLoggedIn && memberTokens) {
      logout(); 
      alert('로그아웃이 되었습니다.');
      navigate('/login');

    } else {
      navigate('/login');
    }
  };

  return (
    <header>
      <div className="header-main">
        <div className="header-main-logo">
          <div className="container">
            <h1><Link to="/">OTok</Link></h1>
          </div>
        </div>
        <div className="header-main-bottom">
          <div className="container">
            <div className="header-main-side left">
              <ul>
                <li className="menu is-pc"><Link>메뉴바</Link></li>
                <li className="menu is-m"><Link>메뉴바</Link></li>
                <li className="mypage"><Link to="">마이페이지</Link></li>
              </ul>
            </div>
            <div className="header-main-cate">
              <ul>
                <li>
                  <Link to="/">홈</Link>
                </li>
                <li>
                  <Link to="/brands/introCompany">브랜드</Link>
                  <dl>
                    <dd><Link to="/brands/introCompany">회사소개</Link></dd>
                    <dd><Link to="">브랜드 스토리</Link></dd>
                    <dd><Link to="">찾아오시는 길</Link></dd>
                  </dl>
                </li>
                <li>
                  <Link to="/products">제품</Link>
                  <dl>
                    <dd><Link to="/products">O.Tok 추천 상품</Link></dd>
                    <dd><Link to="/products">세트 상품</Link></dd>
                  </dl>
                </li>
                <li>
                  <Link to="/events">이벤트</Link>
                  <dl>
                    <dd><Link to="/events">진행중 이벤트</Link></dd>
                    <dd><Link to="/events">종료된 이벤트</Link></dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div className="header-main-side right">
              <ul>
                <li className="cart"><Link to="/carts">장바구니</Link></li>
                <li className="mypage"><Link to="">마이페이지</Link></li>
                <li 
                  className={isLoggedIn && memberTokens ? "header-logout" : "header-login"}
                  style={{cursor: 'pointer'}} 
                  onClick={handleAuthClick}
                >
                  <img 
                    src={isLoggedIn && memberTokens ? logoutimg : loginimg} 
                    alt={isLoggedIn && memberTokens ? '로그아웃 아이콘' : '로그인 아이콘'} 
                  />
                </li>  
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="header-menu">
        <div className="menu-head">
          <Link to="" className="close">닫기</Link>
        </div>
        <div className="menu-body">
          <div className="menu-body-cate">
            <ul>
              <li><Link to="/brands/introCompany"><p>회사소개</p></Link></li>
              <li><Link to=""><p>브랜드스토리</p></Link></li>
              <li><Link to=""><p>찾아오시는 길</p></Link></li>
              <li className="active"><Link to="/products"><p>O.Tok 추천 상품</p></Link></li>
              <li><Link to=""><p>세트 상품</p></Link></li>
              <li><Link to=""><p>진행중 이벤트</p></Link></li>
              <li><Link to=""><p>종료된 이벤트</p></Link></li>
            </ul>
          </div>
          <div className="menu-body-member">
            <ul>
              <li className="cart"><Link to="/carts"><p>장바구니</p></Link></li>
              <li className="mypage"><Link to=""><p>마이페이지</p></Link></li>
              <li className="cs"><Link to=""><p>고객센터</p></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;