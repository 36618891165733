import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import DaumPostcodeEmbed from 'react-daum-postcode';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { formatLocalString } from '../../utils/validation';
import useUserStore from '../../store/userStore';
import { ADDRESS_LIST_URL, ORDER_PRICE_URL, COUPON_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';
import { orderRequest } from '../../services/Payment/Payment';

function Create() {
    const { userInfo } = useUserStore();
    const location = useLocation();
    console.log(location.state?.cartList?.data, '::::state?.cartList?.data')
    // 우편번호 관련 상태
    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [formData, setFormData] = useState({
        zipcode: '',
        address1: '',
        address2: ''
    });
    const [addressList, setAddressList] = useState([]); // 주소록 리스트
    const [selectedAddressType, setSelectedAddressType] = useState('new'); // 기본값은 '새로입력'
    const [calculateItem, setCalculateItem] = useState(location.state?.cartList?.data || []);
    const [recipientInfo, setRecipientInfo] = useState({
        name: '',
        phone1: '',
        phone2: '',
        phone3: ''
    });
    const [selectedMdeSeq, setSelectedMdeSeq] = useState(null);
    const [couponList, setCouponList] = useState([]);
    console.log(couponList, ':::::couponList');
    const [priceInfo, setPriceInfo] = useState([]);
    console.log(priceInfo, ':::priceInfo');
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [appliedCouponAmount, setAppliedCouponAmount] = useState(0);

    // 배송지 타입 변경 핸들러
    const handleAddressTypeChange = (e) => {
        const newType = e.target.value;
        setSelectedAddressType(newType);
        
        if (newType === 'default' && addressList.length > 0) {
            const defaultAddress = addressList.find(addr => addr.default_flag === 'Y');
            if (defaultAddress) {
                setFormData({
                    zipcode: defaultAddress.recipient_zipcode || '',
                    address1: defaultAddress.recipient_address_street || '',
                    address2: defaultAddress.recipient_address_detail || ''
                });
                setRecipientInfo({
                    name: defaultAddress.recipient_name || '',
                    phone1: defaultAddress.recipient_phone?.substring(0, 3) || '',
                    phone2: defaultAddress.recipient_phone?.substring(3, 7) || '',
                    phone3: defaultAddress.recipient_phone?.substring(7) || ''
                });
            }
        } else {
            setFormData({
                zipcode: '',
                address1: '',
                address2: ''
            });
            setRecipientInfo({
                name: '',
                phone1: '',
                phone2: '',
                phone3: ''
            });
        }
    };

    // 우편번호 찾기 핸들러들
    const handleOpenPostcode = (e) => {
        e.preventDefault();
        setIsPostcodeOpen(true);
    };

    // 우편번호 완료 핸들러 수정
    const handleComplete = (data) => {
        setFormData(prevData => ({
            ...prevData,
            zipcode: data.zonecode,
            address1: data.roadAddress,
            address2: ''  // 사용자가 직접 입력하는 상세주소
        }));
        setIsPostcodeOpen(false);
    };

    const handleClosePostcode = () => {
        setIsPostcodeOpen(false);
    };


    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 주소록 리스트 api 호출
    useEffect(  () => {
        const handleGetAddressList = async () => {
        try {
            const response = await instance.post(ADDRESS_LIST_URL)
            setAddressList(response.data.data);
        } catch (error) {
                console.log(error, ':::error');
            }   
        };
        handleGetAddressList();
    }, [])

    // addressList가 로드되면 default_flag 확인 및 주소 데이터 설정
    useEffect(() => {
        if (addressList.length > 0) {
            const defaultAddress = addressList.find(addr => addr.default_flag === 'Y');
            if (defaultAddress) {
                setSelectedAddressType('default');
                setFormData({
                    zipcode: defaultAddress.recipient_zipcode || '',
                    address1: defaultAddress.recipient_address_street || '',
                    address2: defaultAddress.recipient_address_detail || ''
                });
                setRecipientInfo({
                    name: defaultAddress.recipient_name || '',
                    phone1: defaultAddress.recipient_phone?.substring(0, 3) || '',
                    phone2: defaultAddress.recipient_phone?.substring(3, 7) || '',
                    phone3: defaultAddress.recipient_phone?.substring(7) || ''
                });
            }
        }
    }, [addressList]);

    // addressList 데이터 구조 확인을 위한 로그 추가
    useEffect(() => {
        console.log('AddressList:', addressList); // 디버깅용 로그
    }, [addressList]);

    // 팝업 열기
    const popupOpen = (type) => {
        var box = document.querySelector(".popup-box > .box[data-name='" + type + "']");

        document.documentElement.classList.add("fixed");
        document.body.classList.add("fixed");
        document.querySelector(".popup-box").classList.add("fixed");

        if (box) {
            box.classList.add("active");
            var siblings = box.parentElement.querySelectorAll(".box");
            siblings.forEach(function(sibling) {
                if (sibling !== box) {
                    sibling.classList.remove("active");
                }
            });
        }
    }

    // 팝업 닫기
    const popupClose = () => {
        document.documentElement.classList.remove("fixed");
        document.body.classList.remove("fixed");
        document.querySelector(".popup-box").classList.remove("fixed");
    }

    useEffect(() => {
        document.addEventListener("mouseup", function(e) {
            console.log(e.target.className);

            if (e.target.classList.contains("popup-box") && e.target.classList.contains("fixed")) {
                popupClose();
            }
        });
    }, []);

    const handlePayment = async () => {
        try {
            // 필수값 검증
            if (!recipientInfo.name) {
                alert('받는 사람 이름을 입력해주세요.');
                return;
            }

            if (!recipientInfo.phone1 || !recipientInfo.phone2 || !recipientInfo.phone3) {
                alert('받는 사람 연락처를 입력해주세요.');
                return;
            }

            // 결제 요청 데이터 준비
            const orderRequestData = {
                member_seq: userInfo.member_seq,
                settlePrice: priceInfo.settlePrice,
                download_seq: selectedCoupon?.download_seq || "",
                use_point: priceInfo.sumPointUse?.toString() || "0",
                mode: "choice",
                payment: "card",
                items: calculateItem.map(item => ({
                    goods_seq: item.goods_seq,
                    option_seq: item.option_seq || "",
                    ea: item.ea.toString() // ea를 문자열로 변환
                })),
                delivery: {
                    recipient_user_name: recipientInfo.name,
                    recipient_cellphone: [
                        recipientInfo.phone1,
                        recipientInfo.phone2,
                        recipientInfo.phone3
                    ], // 배열 형태로 변경
                    recipient_zipcode: formData.zipcode,
                    recipient_address: formData.address1,
                    recipient_address_street: formData.address1,
                    recipient_address_detail: formData.address2,
                    recipient_memo: null
                }
            };

            console.log('주문 요청 데이터:', orderRequestData);

            // 결제 요청 API 호출
            const orderResponse = await orderRequest(orderRequestData);
            console.log('orderResponse:', orderResponse); // 디버깅용

            if (!orderResponse || !orderResponse.data) {
                throw new Error('Invalid order response');
            }

            // 토스 결제 진행
            const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
            await tossPayments.requestPayment('카드', {
                amount: orderResponse.data.settlePrice?.toString() || priceInfo.settlePrice.toString(),
                orderId: orderResponse.data.orderId || ``,
                orderName: orderResponse.data.orderName || '상품 주문',
                customerName: recipientInfo.name,
                // successUrl: `${window.location.origin}/orders/result/`,
                successUrl: `${window.location.origin}/payment/result`,
                failUrl: `${window.location.origin}/orders/create`,
                            // 웹앱 환경을 위한 추가 설정
            flowMode: 'DIRECT',
            easyPay: 'TOSSPAY',
            windowTarget: '_self'
            });
        } catch (error) {
            console.error('결제 에러:', error);
            alert('결제 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
    };

    // useEffect 수정
    useEffect(() => {
        const handleOrderPrice = async () => {
            // location.state가 없을 경우 처리
            if (!location.state?.cartList?.data) {
                console.error('장바구니 데이터가 없습니다.');
                return;
            }

            // 포인트가 유효하지 않으면 API 호출하지 않음
            if (priceInfo.sumPointUse > priceInfo.useAbleOrderPoint) {
                return;
            }

            const items = location.state.cartList.data.map(item => ({
                goods_seq: item.goods_seq,
                option_seq: item.option_seq || "",
                ea: item.ea
            }));

            const requestBody = {
                member_seq: userInfo.member_seq,
                items: items,
                download_seq: location.state.payInfo?.download_seq || "",
                use_point: priceInfo.sumPointUse?.toString() || "0",
                recipient_zipcode: formData.zipcode || "",
                recipient_address_street: formData.address1 || ""
            };

            try {
                const response = await instance.post(ORDER_PRICE_URL, requestBody);
                setPriceInfo(prev => ({
                    ...prev,
                    ...response.data.data,
                    sumPointUse: prev.sumPointUse // 기존 포인트 값 유지
                }));
                const formattedItems = response.data.data.items.map(item => ({
                    ...item,
                    consumer_price: Number(item.unit_price || 0),
                    price: Number(item.unit_goods_price || 0),
                    sale_percent: Number(item.sale_percent || 0)
                }));
                setCalculateItem(formattedItems);
            } catch (error) {
                console.error('API Error:', error);
            }
        };

        handleOrderPrice();
    }, [
        priceInfo.useAbleOrderPoint,
        userInfo.member_seq, 
        location.state?.cartList?.data, 
        location.state?.payInfo?.download_seq,
        priceInfo.sumPointUse,
        formData.zipcode,
        formData.address1,
        appliedCouponAmount
    ]);

    // 주소 선택 핸들러 수정
    const handleAddressSelect = (address) => {
        console.log('Selected Address:', address);
        setSelectedMdeSeq(address.mde_seq);
    };

    // 주소 적용 핸들러 수정
    const handleApplyAddress = () => {
        console.log('Current selectedMdeSeq:', selectedMdeSeq);
        
        if (!selectedMdeSeq) {
            alert('주소를 선택해주세요.');
            return;
        }

        const selectedAddress = addressList.find(addr => addr.mde_seq === selectedMdeSeq);
        console.log('Selected Address:', selectedAddress);

        if (selectedAddress) {
            setFormData({
                zipcode: selectedAddress.recipient_zipcode || '',
                address1: selectedAddress.recipient_address_street || '',
                address2: selectedAddress.recipient_address_detail || ''
            });
            
            setRecipientInfo({
                name: selectedAddress.recipient_name || '',
                phone1: selectedAddress.recipient_phone?.substring(0, 3) || '',
                phone2: selectedAddress.recipient_phone?.substring(3, 7) || '',
                phone3: selectedAddress.recipient_phone?.substring(7) || ''
            });
            
            setSelectedAddressType('default');
            popupClose();
        }
    };

    useEffect(() => {
        const handleGetCouponList = async () => {
            const response = await instance.post(COUPON_LIST_URL, {
                settle_price: priceInfo.settlePrice,
                offset: 1
            });
            setCouponList(response.data.data)
        };
        handleGetCouponList();
    }, [priceInfo.settlePrice]);

    // 쿠폰 선택 핸들러 수정
    const handleCouponSelect = (coupon) => {
        setSelectedCoupon(coupon);
        if (coupon) {
            setAppliedCouponAmount(coupon.discounted_price || 0);
            // 선택된 쿠폰의 download_seq 설정 및 API 호출을 위한 상태 업데이트
            location.state.payInfo = {
                ...location.state.payInfo,
                download_seq: coupon.download_seq
            };
        } else {
            setAppliedCouponAmount(0);
            location.state.payInfo = {
                ...location.state.payInfo,
                download_seq: ""
            };
        }
    };

    // 쿠폰 적용 핸들러 수정
    const handleApplyCoupon = () => {
        if (!selectedCoupon && appliedCouponAmount === 0) {
            location.state.payInfo = {
                ...location.state.payInfo,
                download_seq: ""
            };
            popupClose();
            return;
        }
        
        if (selectedCoupon) {
            setAppliedCouponAmount(selectedCoupon.discounted_price || 0);
            location.state.payInfo = {
                ...location.state.payInfo,
                download_seq: selectedCoupon.download_seq
            };
            popupClose();
        } else {
            alert('쿠폰을 선택해주세요.');
        }
    };

    // 최대 할인 적용 핸들러 수정
    const handleMaxDiscountApply = (e) => {
        if (e.target.checked) {
            if (couponList && couponList.length > 0) {
                const maxDiscountCoupon = [...couponList].sort((a, b) => 
                    (b.discounted_price || 0) - (a.discounted_price || 0)
                )[0];
                
                setSelectedCoupon(maxDiscountCoupon);
                setAppliedCouponAmount(maxDiscountCoupon.discounted_price || 0);
                // 최대 할인 쿠폰의 download_seq 설정
                location.state.payInfo = {
                    ...location.state.payInfo,
                    download_seq: maxDiscountCoupon.download_seq
                };
            }
        } else {
            setSelectedCoupon(null);
            setAppliedCouponAmount(0);
            location.state.payInfo = {
                ...location.state.payInfo,
                download_seq: ""
            };
        }
    };

    // 포인트 모두사용하기 핸들러 수정
    const handleUseAllPoints = async (e) => {
        if (e.target.checked) {
            // useAbleOrderPoint 값을 1000단위로 내림
            const roundedPoint = Math.floor(priceInfo.useAbleOrderPoint / 1000) * 1000;
            
            try {
                const items = location.state.cartList.data.map(item => ({
                    goods_seq: item.goods_seq,
                    option_seq: item.option_seq || "",
                    ea: item.ea
                }));

                const requestBody = {
                    member_seq: userInfo.member_seq,
                    items: items,
                    download_seq: location.state.payInfo.download_seq || "",
                    use_point: roundedPoint.toString(),
                    recipient_zipcode: formData.zipcode || "",
                    recipient_address_street: formData.address1 || ""
                };

                const response = await instance.post(ORDER_PRICE_URL, requestBody);
                
                if (response.data.code === -200) {
                    alert(response.data.message || "포인트는 천단위로 입력 가능합니다.");
                    setPriceInfo(prev => ({
                        ...prev,
                        sumPointUse: 0
                    }));
                    e.target.checked = false; // 체크박스 해제
                } else {
                    setPriceInfo(prev => ({
                        ...prev,
                        sumPointUse: roundedPoint
                    }));
                }
            } catch (error) {
                alert("포인트 적용 중 오류가 발생했습니다.");
                setPriceInfo(prev => ({
                    ...prev,
                    sumPointUse: 0
                }));
                e.target.checked = false; // 체크박스 해제
            }
        } else {
            // 체크 해제시 초기화
            setPriceInfo(prev => ({
                ...prev,
                sumPointUse: 0
            }));
        }
    };

    // 포인트 입력 핸들러 수정
    const handlePointInput = (e) => {
        const inputValue = Number(e.target.value.replace(/,/g, ''));
        
        // 임시로 화면에만 반영
        setPriceInfo(prev => ({
            ...prev,
            sumPointUse: inputValue
        }));
    };


    // 포인트 입력 완료 핸들러 추가
    const handlePointInputComplete = async (e) => {
        const inputValue = Number(e.target.value.replace(/,/g, ''));
        
        // 사용가능한 포인트보다 크거나 결제금액보다 큰 경우
        if (inputValue > priceInfo.useAbleOrderPoint || inputValue > priceInfo.settlePrice) {
            alert('사용할 수 없는 포인트입니다.');
            setPriceInfo(prev => ({
                ...prev,
                sumPointUse: 0
            }));
            return;
        }

        try {
            const items = location.state.cartList.data.map(item => ({
                goods_seq: item.goods_seq,
                option_seq: item.option_seq || "",
                ea: item.ea
            }));

            const requestBody = {
                member_seq: userInfo.member_seq,
                items: items,
                download_seq: location.state.payInfo.download_seq || "",
                use_point: inputValue.toString(),
                recipient_zipcode: formData.zipcode || "",
                recipient_address_street: formData.address1 || ""
            };

            const response = await instance.post(ORDER_PRICE_URL, requestBody);
            
            if (response.data.code === -200) {
                alert(response.data.message);
                setPriceInfo(prev => ({
                    ...prev,
                    sumPointUse: 0
                }));
            } else {
                setPriceInfo(prev => ({
                    ...prev,
                    sumPointUse: inputValue
                }));
            }
        } catch (error) {
            alert("포인트 적용 중 오류가 발생했습니다.");
            setPriceInfo(prev => ({
                ...prev,
                sumPointUse: 0
            }));
        }
    };

    return (
        <section>
            <div className="container">
                <div className="title-box mb40 mb-lg-24">
                    <h2>주문/결제</h2>
                </div>
                <div className="item-cart-box order">
                    <div className="cart-left">
                        <div className="cart-head py28 py-lg-0">
                            &nbsp;
                        </div>
                        {calculateItem && calculateItem.length > 0 && calculateItem.map((item, index) => (
                        <div className="cart-body" key={item.cart_id || index}>
                            <ul>
                                <li>
                                    <div className="item">
                                        <div className="inner">
                                            <div className="list-image" style={{backgroundImage:`url(${item.goods_image})`}}></div>
                                            <div className="list-content">
                                                <div className="subject">
                                                    <b>O.Tok</b>
                                                    <p>{item.goods_name}</p>
                                                    {/* <small>옵션: [O.Tok]국내최초 천연 산호칼슘 일야채 세정제</small> */}
                                                </div>
                                                <div className="price">
                                                    <small><strike>{formatLocalString(item.consumer_price || 0)}</strike>원</small>
                                                    <div>
                                                        <strong>{item.sale_percent || 0}%</strong>
                                                        <p>{formatLocalString(item.price || 0)}<em>원</em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cell">
                                        <p>배송비 별도</p>
                                    </div>
                                    <div className="cell">
                                        <p><b>{formatLocalString(item.price || 0)}</b>원</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        ))}
                    </div>
                    <div className="cart-middle mt40 mt-lg-24">
                        <div className="write-list-box">
                            <h3>배송지 정보</h3>
                            <ul>
                                <li>
                                    <strong className="form-title">배송지 선택</strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc flex-lg-tj">
                                            <div className="flex flex-vc mr24 mr-lg-0">
                                                <div className="check-box mr16">
                                                    <input 
                                                        type="radio" 
                                                        name="radio01" 
                                                        id="radio01_01"
                                                        value="default"
                                                        checked={selectedAddressType === 'default'}
                                                        onChange={handleAddressTypeChange}
                                                    />
                                                    <label htmlFor="radio01_01">기본주소</label>
                                                </div>
                                                <div className="check-box">
                                                    <input 
                                                        type="radio" 
                                                        name="radio01" 
                                                        id="radio01_02"
                                                        value="new"
                                                        checked={selectedAddressType === 'new'}
                                                        onChange={handleAddressTypeChange}
                                                    />
                                                    <label htmlFor="radio01_02">새로입력</label>
                                                </div>
                                            </div>
                                            <div className="button-box">
                                                <button onClick={() => popupOpen('popup01')} className="btn btn-gray f12 px16 h40">주소록 보기</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">받으시는 분</strong>
                                    <div className="form-content flex flex-vc">
                                        <div className="input-box w450 flex-lg-1">
                                            <input 
                                                type="text" 
                                                placeholder="성명" 
                                                value={selectedAddressType === 'default' ? addressList[0]?.recipient_name : recipientInfo.name}
                                                onChange={(e) => setRecipientInfo(prev => ({ ...prev, name: e.target.value }))}
                                                readOnly={selectedAddressType === 'default'}
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">핸드폰 번호</strong>
                                    <div className="form-content flex flex-vc">
                                        <div className="flex flex-vc w450 flex-lg-1">
                                            <div className="input-box flex-1 mr6">
                                                <input 
                                                    type="text" 
                                                    placeholder="010" 
                                                    value={selectedAddressType === 'default' ? addressList[0]?.recipient_phone?.substring(0, 3) : recipientInfo.phone1}
                                                    onChange={(e) => setRecipientInfo(prev => ({ ...prev, phone1: e.target.value }))}
                                                    readOnly={selectedAddressType === 'default'}
                                                />
                                            </div>
                                            <div className="input-box flex-1 mr6">
                                                <input 
                                                    type="text" 
                                                    placeholder="" 
                                                    value={selectedAddressType === 'default' ? addressList[0]?.recipient_phone?.substring(3, 7) : recipientInfo.phone2}
                                                    onChange={(e) => setRecipientInfo(prev => ({ ...prev, phone2: e.target.value }))}
                                                    readOnly={selectedAddressType === 'default'}
                                                />
                                            </div>
                                            <div className="input-box flex-1">
                                                <input 
                                                    type="text" 
                                                    placeholder="" 
                                                    value={selectedAddressType === 'default' ? addressList[0]?.recipient_phone?.substring(7) : recipientInfo.phone3}
                                                    onChange={(e) => setRecipientInfo(prev => ({ ...prev, phone3: e.target.value }))}
                                                    readOnly={selectedAddressType === 'default'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 주소</strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc">
                                            <div className="flex flex-vc w450 flex-lg-1">
                                                <div className="input-box flex-1 mr6">
                                                    <input 
                                                        type="text"
                                                        value={formData.zipcode || ''}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="button-box">
                                                    <button 
                                                        className="btn btn-gray px16 f12" 
                                                        onClick={handleOpenPostcode}
                                                        disabled={selectedAddressType === 'default'}
                                                    >
                                                        우편번호 찾기
                                                    </button>
                                                </div>
                                                {isPostcodeOpen && (
                                                    <div style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1000,
                                                        width: '600px',
                                                        height: '400px',
                                                        background: 'white',
                                                        border: '1px solid #ccc'
                                                    }}>
                                                        <DaumPostcodeEmbed
                                                            onComplete={handleComplete}
                                                            onClose={handleClosePostcode}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                name="address1"
                                                value={formData.address1 || ''}
                                                readOnly
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input 
                                                type="text" 
                                                name="address2"
                                                value={formData.address2 || ''}
                                                onChange={selectedAddressType === 'default' ? undefined : handleAddressChange}
                                                placeholder="상세주소 입력"
                                                readOnly={selectedAddressType === 'default'}
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">배송 메세지</strong>
                                    <div className="form-content">
                                        <div className="select-box">
                                            <select>
                                                <option>배송 시 요청사항을 선택해 주세요.</option>
                                                <option>부재 시 문 앞에 놓아주세요.</option>
                                                <option>부재 시 경비실에 맡겨주세요.</option>
                                                <option>부재  화 또는 문자 주세요.</option>
                                                <option>배송 전에 연락 주세요.</option>
                                                <option>직접입력</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="write-list-box mt40 mt-lg-24">
                            <h3>할인받기</h3>
                            <ul>
                                <li>
                                    <strong className="form-title">할인쿠폰</strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc flex-lg-tj">
                                            <div className="input-box unit mr24 mr-lg-0 w280 col-lg-12">
                                                <input 
                                                    type="text" 
                                                    value={formatLocalString(appliedCouponAmount)}
                                                    readOnly
                                                />
                                                <em>원</em>
                                            </div>
                                            <div className="button-box col-lg-12 mt-lg-8 mr24 mr-lg-0">
                                                <button onClick={() => popupOpen('popup02')} className="btn btn-gray f12 px16">쿠폰 조회/적용</button>
                                            </div>
                                            <div className="check-box mt-lg-8">
                                                <input 
                                                    type="checkbox" 
                                                    name="check" 
                                                    id="check"
                                                    onChange={handleMaxDiscountApply}
                                                />
                                                <label htmlFor="check"><p>최대 할인 적용</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">포인트<small className="is-m">보유 2,301p</small></strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc flex-lg-tj">
                                            <div className="input-box unit mr24 mr-lg-0 w280 col-lg-12">
                                                <input 
                                                    type="text" 
                                                    value={formatLocalString(priceInfo.sumPointUse || 0)}
                                                    onChange={handlePointInput}
                                                    onBlur={handlePointInputComplete}  // 포커스를 잃을 때
                                                />
                                                <em>P</em>
                                            </div>
                                            <div className="is-pc mr24">
                                                <p className="f13 lh1-5">
                                                    보유 {formatLocalString(priceInfo.total_point ?? 0)}p
                                                </p>
                                            </div>
                                            <div className="check-box mt-lg-8">
                                                <input 
                                                    type="checkbox" 
                                                    name="check" 
                                                    id="check_point"
                                                    onChange={handleUseAllPoints}
                                                />
                                                <label htmlFor="check_point"><p>모두사용하기</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cart-right">
                        <ul>
                            <li>
                                <p>상품금액</p>
                                <p>{priceInfo.sumGoodsPrice ? formatLocalString(priceInfo.sumGoodsPrice) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <p>배송비</p>
                                <p>+{priceInfo.shipping_cost ? formatLocalString(priceInfo.shipping_cost) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <p>할인금액</p> 
                                <p>-{priceInfo.sumCouponUse ? formatLocalString(priceInfo.sumCouponUse) : 0}<em>원</em></p>
                            </li>
                            <li className="border">
                                <p>포인트 사용</p>
                                <p>-{priceInfo.sumPointUse ? formatLocalString(priceInfo.sumPointUse) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <b>총 결제금액</b>
                                <b>{priceInfo.settlePrice ? formatLocalString(priceInfo.settlePrice) : 0}
                                <em>원</em></b>
                            </li>
                            <li>
                                <small>적립예정 포인트</small>
                                <small>{location.state?.payInfo?.reserve ? formatLocalString(location.state.payInfo.reserve) : 0}<em>p</em></small>
                            </li>
                        </ul>
                        <div className="button-box" onClick={handlePayment}>
                            <Link className="btn btn-active f13">결제하기</Link>
                        </div>
                    </div>
                    <div className="cart-button">
                        <div className="button-box">
                            <button to="/orders/result" className="btn btn-active f13" onClick={handlePayment}>결제하1기</button>
                        </div>
                    </div>
                </div>

                {/* 주소록 선택 팝업 */}
                <div className="popup-box">
                    <div className="box basic" data-name="popup01">
                        <div className="popup-head">
                            <button type="button" className="close" onClick={() => {popupClose()}}>닫기</button>
                        </div>
                        <div className="popup-body">
                            <div className="address-select-box">
                                <h3>주소록 선택</h3>
                                <ul>
                                    {addressList.map((address, index) => (
                                        <li key={address.mde_seq}>
                                            <input 
                                                type="radio" 
                                                name="radio02" 
                                                id={`radio02_${index}`}
                                                checked={selectedMdeSeq === address.mde_seq}
                                                onChange={() => handleAddressSelect(address)}
                                            />
                                            <label htmlFor={`radio02_${index}`}>
                                                <div>
                                                    <strong>
                                                        {address.default_flag === "Y" && <em>기본</em>}
                                                        {address.mde_title} ({address.recipient_name})
                                                    </strong>
                                                    <p>{address.recipient_zipcode} {address.recipient_address_street} {address.recipient_address_detail}</p>
                                                    <p>{address.recipient_phone}</p>
                                                </div>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="popup-foot">
                            <div className="button-box">
                                <button className="btn btn-black f13" onClick={handleApplyAddress}>선택</button>
                            </div>
                        </div>
                    </div>



                    {/* 사용가능 쿠폰 팝업 */}
                    <div className="box basic" data-name="popup02">
                        <div className="popup-head">
                            <button type="button" className="close" onClick={() => {popupClose()}}>닫기</button>
                        </div>
                        <div className="popup-body">
                            <div className="coupon-select-box">
                                <h3>사용 가능 쿠폰</h3>
                                <ul>
                                    <li>
                                        <input 
                                            type="radio" 
                                            name="radio03" 
                                            id="radio03_00"
                                            onChange={() => {
                                                handleCouponSelect(null);
                                                setAppliedCouponAmount(0);
                                            }}
                                        />
                                        <label htmlFor="radio03_00">
                                            <div>
                                                <p>선택안함</p>
                                            </div>
                                        </label>
                                    </li>
                                    {couponList && couponList.map((coupon, index) => (
                                        <li key={coupon.coupon_seq || index}>
                                            <input 
                                                type="radio" 
                                                name="radio03" 
                                                id={`radio03_${index + 1}`}
                                                onChange={() => handleCouponSelect(coupon)}
                                                checked={selectedCoupon?.coupon_seq === coupon.coupon_seq}
                                            />
                                            <label htmlFor={`radio03_${index + 1}`}>
                                                <div>
                                                    <strong>-{formatLocalString(coupon.discounted_price || 0)}원</strong>
                                                    <p>[{coupon.discount_rate || 0}% 혜택] {coupon.coupon_name}</p>
                                                    <time>{coupon.use_end_date.split(' ')[0]} 까지</time>
                                                </div>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="popup-foot">
                            <div className="button-box">
                                <button className="btn btn-black f13" onClick={handleApplyCoupon}>선택</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Create;
