import React from "react";
import SidebarMenu from "../../components/SidebarMenu";

function Index() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="이용안내" />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>이용안내</h2>
                        </div>
                        <div className="guide-box">
                            <ul>
                                <li>
                                    <h3>제1조(목적)</h3>
                                    <ul>
                                        <li>
                                            이 약관은 (주)아하모먼트(전자상거래 사업자)가 운영하는
                                            (주)오톡 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷
                                            관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과
                                            이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
                                            <br />
                                            <br />
                                            ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에
                                            반하지 않는 한 이 약관을 준용합니다.」
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>제2조(정의)</h3>
                                    <ul>
                                        <li>
                                            “몰”이란 (주)오톡이 재화 또는 용역(이하 “재화 등”이라 함)을
                                            이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여
                                            재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며,
                                            아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
                                        </li>
                                        <li>
                                            “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는
                                            서비스를 받는 회원 및 비회원을 말합니다.
                                        </li>
                                        <li>
                                            ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이
                                            제공하는 서비스를 이용할 수 있는 자를 말합니다.
                                        </li>
                                        <li>
                                            ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는
                                            서비스를 이용하는 자를 말합니다.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>제3조 (약관 등의 명시와 설명 및 개정)</h3>
                                    <ul>
                                        <li>
                                            “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
                                            주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                                            전화번호․모사전송번호․전자우편주소, 사업자등록번호,
                                            통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알
                                            수 있도록 00 사이버몰의 초기 서비스화면(전면)에 게시합니다.
                                            다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할
                                            수 있습니다.
                                        </li>
                                        <li>
                                            “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는
                                            내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을
                                            이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
                                            제공하여 이용자의 확인을 구하여야 합니다.
                                        </li>
                                        <li>
                                            “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」,
                                            「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
                                            「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
                                            정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
                                            「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을
                                            개정할 수 있습니다.
                                        </li>
                                        <li>
                                            “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
                                            현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터
                                            적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게
                                            약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을
                                            두고 공지합니다. 이 경우 "몰“은 개정 전 내용과 개정 후 내용을
                                            명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>제4조(서비스의 제공 및 변경)</h3>
                                    <ul>
                                        <li>
                                            “몰”은 다음과 같은 업무를 수행합니다.
                                            <br />
                                            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
                                            <br />
                                            2. 구매계약이 체결된 재화 또는 용역의 배송
                                            <br />
                                            3. 기타 “몰”이 정하는 업무
                                        </li>
                                        <li>
                                            “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
                                            경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의
                                            내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는
                                            용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의
                                            내용을 게시한 곳에 즉시 공지합니다.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Index;
