import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function Create() {


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"세금계산서 내역"} />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>세금계산서</h2>
                        </div>
                        <div className="board-write-box">
                            <ul>
                                <li>
                                    <strong>아이디</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    className="h40"
                                                    placeholder=""
                                                    value="canksh12"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>제목</strong>
                                    <div>
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder=""
                                                value="세금계산서 요청합니다."
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>내용</strong>
                                    <div>
                                        <div className="textarea-box lg">
                                            <textarea
                                                placeholder="※ 세금계산서 발행은 제품을 구입한 다음 달 10일까지 가능하며, 이후에는 발행될 수 없습니다.&#13;&#10;※ 사업자등록증을 파일로 첨부하시면 아래의 항목은 입력하실 필요가 없습니다.&#13;&#10;&#13;&#10;상호명:&#13;&#10;대표자:&#13;&#10;사업자등록번호:&#13;&#10;사업장소재지:&#13;&#10;업태:&#13;&#10;종목:"
                                            ></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>정보</strong>
                                    <div>
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder="주문번호 입력 (모르실 경우 공란)"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder="주문일 입력"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder="주문자명 입력"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder="결제(입금) 금액"
                                            />
                                        </div>
                                        <div className="input-box mt8">
                                            <input
                                                type="text"
                                                className="h40"
                                                placeholder="세금계산서를 받을 이메일 입력"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>파일</strong>
                                    <div>
                                        <div className="w700 col-lg-12">
                                            <div className="file-box">
                                                <p>파일 업로드</p>
                                                <label htmlFor="file">
                                                    첨부파일
                                                    <input type="file" name="file" id="file" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="check-box mt20">
                            <input type="checkbox" name="check" id="check" />
                            <label htmlFor="check" className="flex-vt">
                                <p className="fw7 flex-1">
                                    개인정보 수집/이용 동의 (필수)
                                    <small>
                                        목적: 쇼핑몰에서 제공하는 게시판 이용, 일반 게시판에 게시글 등록,
                                        불법, 광고 게시글 등록 방지 <br />
                                        항목: 이름,비밀번호, 이메일, 작성내용, IP 첨부(파일에 개인정모 포함
                                        시에만 수집, 이용) <br />
                                        보유기간: 게시글 삭제 시 까지
                                    </small>
                                </p>
                            </label>
                        </div>
                        <div className="button-box mt32">
                            <div className="w200 flex-lg-1 mr8">
                                <Link to="/mypage/bill" className="btn btn-white">
                                    취소
                                </Link>
                            </div>
                            <div className="w200 flex-lg-1">
                                <Link to="" className="btn btn-black">
                                    등록
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Create;
