import React from "react";
import SidebarMenu from "../../components/SidebarMenu";

function Index() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="회사소개" />
                    <div className="sub-right">
                        <div className="is-pc">
                            <div className="title-box">
                                <h2>회사소개</h2>
                            </div>
                        </div>
                        <div className="intro-box">
                            <h3 className="is-m">
                                Nature to Nature
                                <br />
                                바이오매스를 통해
                                <br />
                                진짜 자연을 고민합니다.
                            </h3>
                            <img src="../../asset/images/img_intro.png" alt="Introduction" />
                            <h3 className="is-pc">
                                Nature to Nature
                                <br />
                                바이오매스를 통해 진짜 자연을 고민합니다.
                            </h3>
                            <ul>
                                <li>
                                    <small>Sustainability 지속가능성</small>
                                    <b>지속가능한 원료만을 선택합니다.</b>
                                    <p>
                                        내가 먹고, 입고, 생활하면서 닿는 모든것의 원료는 건강해야 합니다.
                                        오톡은 천연원료를 사용하고 인공적인 조작을 엄격하게 제한합니다.
                                        모든 오톡의 제품들은 자연의 원료를 사용하고 화학부형제,
                                        화학보존료, 인공착향료, 유전자조작식품 등을 엄격히 제한합니다.
                                        그리고 이 모든 정보를 소비자에게 깨끗하고 투명하게 공개합니다.
                                    </p>
                                </li>
                                <li>
                                    <small>Upcycle/Recycle 업사이클/리사이클</small>
                                    <b>재생가능한 생산과 가치있는 소비를 고민합니다.</b>
                                    <p>
                                        내가 먹고, 입고, 생활하면서 닿는 모든것의 원료는 건강해야 합니다.
                                        오톡은 천연원료를 사용하고 인공적인 조작을 엄격하게 제한합니다.
                                        모든 오톡의 제품들은 자연의 원료를 사용하고 화학부형제,
                                        화학보존료, 인공착향료, 유전자조작식품 등을 엄격히 제한합니다.
                                        그리고 이 모든 정보를 소비자에게 깨끗하고 투명하게 공개합니다.
                                    </p>
                                </li>
                                <li>
                                    <small>Eco-Friendly Material 친환경원료</small>
                                    <b>자연에서 ...</b>
                                    <p>
                                        내가 먹고, 입고, 생활하면서 닿는 모든것의 원료는 건강해야 합니다.
                                        오톡은 천연원료를 사용하고 인공적인 조작을 엄격하게 제한합니다.
                                        모든 오톡의 제품들은 자연의 원료를 사용하고 화학부형제,
                                        화학보존료, 인공착향료, 유전자조작식품 등을 엄격히 제한합니다.
                                        그리고 이 모든 정보를 소비자에게 깨끗하고 투명하게 공개합니다.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
