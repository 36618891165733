// 토큰 요청 URL
export const TOKEN_URL = "/api/auth";

// 로그인 요청 URL
export const LOGIN_INFO_URL =  "/api/login";

// 회원가입 요청 URL
export const JOIN_INFO_URL = "/api/register";

// 아이디 사용가능한지 체크
export const ID_CHECK_URL = "/api/myIdCheck";

// 아이디 찾기
export const ID_FIND_URL = "/api/idFind";

// 비밀번호 찾기
export const PASSWORD_FIND_URL = "/api/passwordFind";

// 비밀번호 재설정
export const PASSWORD_CHANGE_URL = "/api/passwordChange";

// MAIN 페이지 리스트
export const MAIN_LIST_URL = "api/main/banner/list";

// 상품문의 등록
export const PRODUCT_CREATE_URL = "api/board/common/upload";

// 상품문의, 1:1문의, 상품후기 리스트
export const PRODUCT_LIST_URL = "api/board/common/list";

// 상품 상세 리스트
export const PRODUCT_DETAIL_URL = "api/board/common/detail";

// 상품 타입 리스트
export const PRODUCT_TYPE_URL = "api/board/common/type";



// 공지사항 API
export const NOTICE_LIST_URL = "api/board/notice/list"; // 공지사항 리스트
export const NOTICE_DETAIL_URL = "api/board/notice/detail"; // 공지사항 상세

// FAQ API
export const FAQ_LIST_URL = "api/board/faq/list"; // FAQ 리스트
export const FAQ_DETAIL_URL = "api/board/faq/detail"; // FAQ 상세
export const FAQ_TYPE_URL = "api/board/faq/type"; // FAQ 타입 리스트

// 1:1 문의 API
export const INQUIRY_DETAIL_URL = "api/board/common/detail"; // 1:1 문의 상세
export const INQUIRY_TYPE_URL = "api/board/common/type"; // 1:1 문의 타입 리스트
export const INQUIRY_CREATE_URL = "api/board/common/upload"; // 1:1 문의 등록
export const INQUIRY_UPDATE_URL = "api/board/common/modify"; // 1:1 문의 수정
export const INQUIRY_DELETE_URL = "api/board/common/delete"; // 1:1 문의 삭제

// 세금계산서 API
export const TAX_LIST_URL = "api/board/tax/list"; // 세금계산서 리스트
export const TAX_DETAIL_URL = "api/board/tax/detail"; // 세금계산서 상세
export const TAX_CREATE_URL = "api/board/tax/upload"; // 세금계산서 등록
export const TAX_UPDATE_URL = "api/board/tax/modify"; // 세금계산서 수정
export const TAX_DELETE_URL = "api/board/tax/delete"; // 세금계산서 삭제

// 상품후기 API
export const PRODUCT_REVIEW_DETAIL_URL = "api/board/credit/detail"; // 상품후기 상세
export const PRODUCT_REVIEW_CREATE_URL = "api/board/common/upload"; // 상품후기 등록
export const PRODUCT_REVIEW_UPDATE_URL = "api/board/common/modify"; // 상품후기 수정
export const PRODUCT_REVIEW_DELETE_URL = "api/board/common/delete"; // 상품후기 삭제


// 상품페이지 API
export const PRODUCT_LISTPAGE_URL = "api/getGoodsList"; // 제품 리스트
export const PRODUCT_DETAIL_INFO_URL = "api/goods/detail"; // 제품 상세
export const PRODUCT_REVIEW_URL = "api/main/goods/like"; // 상품 좋아요 및 취소

// 장바구니 API
export const CART_ADD_URL = "api/goods/cart"; // 장바구니 추가
export const CART_LIST_URL = "api/goods/cartList"; // 장바구니 리스트


// 배송지 주소 리스트 API
export const ADDRESS_LIST_URL = "api/mypage/deliveryList"; // 배송지 주소 리스트

// 결제금액 계산 API
export const ORDER_PRICE_URL = "api/pay/calculate"; // 결제금액 계산

// 쿠폰 리스트 API
export const COUPON_LIST_URL = "api/mypage/couponList"; // 쿠폰 리스트

// 결제 요청 API
export const ORDER_REQUEST_URL = "api/pay/request"; // 결제 요청