import instance from "../services";
import { JOIN_INFO_URL, ID_CHECK_URL } from "../../utils/urls";

export const joinUser = async ({
    mem_type,
    user_name,
    mem_phone,
    email,
    userid,
    password,
    business_name,
    business_num,
    business_kind,
    business_zipcode,
    business_address_street,
    business_address_detail,
    business_certificate,
    push_id,
    mailing,
    sms
}) => {
    try {
                // 요청 데이터 로깅
                console.log('회원가입 요청 데이터:', {
                    mem_type,
                    user_name,
                    mem_phone,
                    email,
                    userid,
                    password: '***', // 보안을 위해 비밀번호는 숨김
                    business_name,
                    business_num,
                    business_kind,
                    business_zipcode,
                    business_address_street,
                    business_address_detail,
                    business_certificate,
                    push_id,
                    mailing,
                    sms
                });

        const response = await instance.post(JOIN_INFO_URL, {
            mem_type,
            user_name,
            mem_phone,
            email,
            userid,
            password,
            business_name,
            business_num,
            business_kind,
            business_zipcode,
            business_address_street,
            business_address_detail,
            business_certificate,
            push_id,
            mailing,
            sms
        });

        return response;
    } catch (error) {
        console.error('회원가입 에러 상세:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        throw error;
    }
};

/**
 * 유저 아이디 중복 체크
 * @param userid
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

export const checkUserId = async ({userid}) => {
    try {
        return await instance.post(ID_CHECK_URL, {userid});
    } catch (e) {
        console.error(e)
    }
}