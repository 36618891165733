import React from 'react';
import SidebarMenu from "../../components/SidebarMenu";
import { Link } from 'react-router-dom';

function Index() {
    return (
        <section>
            <div className="container">
                <div className="sub-box main">
                    <SidebarMenu activeItem=""/>
                    <div className="sub-right">
                        <div className="board-title-box">
                            <h3>최근 공지사항</h3>
                            <Link className="link">
                                더보기
                            </Link>
                        </div>
                        <div className="board-list-box">
                            <ul>
                                {Array.from({ length: 5 }).map((_, index) => (
                                    <li key={index}>
                                        <Link className="inner">
                                            <div className="list-left">
                                                <div className="subject">
                                                    {index === 0 && <b className="notice">공지</b>}
                                                    <p>[O.Tok] 새롭게 변경된 환불 및 교환 정책 변경 안내</p>
                                                </div>
                                            </div>
                                            <div className="list-right">
                                                <div className="date">
                                                    <time>2024.09.26</time>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="board-title-box mt40">
                            <h3>Q&A Top 5</h3>
                            <Link className="link">
                                더보기
                            </Link>
                        </div>
                        <div className="board-qna-box">
                            <ul>
                                {Array.from({ length: 5 }).map((_, index) => (
                                    <li key={index}>
                                        <button type="button" className="inner">
                                            <em>Q</em>
                                            <div>
                                                <strong>
                                                    천연 과일세정제는 유기농 과일에도 사용해야 하나요?
                                                </strong>
                                                <p>
                                                    네, 유기농 과일도 재배 과정에서 먼지나 미세 오염물질이 묻을
                                                    수 있습니다.
                                                    <br />
                                                    천연 과일세정제를 사용하면 더 깨끗하게 과일을 세정할 수
                                                    있으며, 안심하고 드실 수 있습니다.
                                                    <br />
                                                    세정제 또한 천연 성분이라 유기농 과일에도 안전하게 사용할
                                                    수 있습니다.
                                                </p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
