import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";

function PopupCarts({isPopup, setIsPopup}) {

    const location = useLocation();

    const [popupActive,setPopupActive] = useState()

    useEffect(() => {
        const body = document.body;

        body.style.overflow = isPopup ? 'hidden' : 'auto';
        
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup]);

    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }

        // 타이머 클린업
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);
    
    if(isPopup)
    return (
        <div className="popup-box-component no-script">
            <div className={`box sm pt0 px0 pb40 ${popupActive ? 'active' : ''}`}>
                <div className="popup-head">
                    <button type="button" className="close" onClick={() => {setIsPopup(false)}}>닫기</button>
                </div>
                <div className="popup-body">
                    <div className="text-box">
                        <p className="tc">상품이 장바구니에 담겼습니다.</p>
                    </div>
                    <div className="button-box mt32">
                        <div className="w135">
                            <Link to="/carts" className="btn btn-white f13">장바구니 바로가기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default PopupCarts;
