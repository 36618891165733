import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import instance from '../../services/services';

function PaymentResult() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const processPayment = async () => {
            try {
                const paymentKey = searchParams.get('paymentKey');
                const orderId = searchParams.get('orderId');
                const amount = searchParams.get('amount');

                console.log('Payment Request Data:', {
                    paymentKey,
                    orderId,
                    amount
                });

                const response = await instance.post('/api/pay/payment_request_mobile', {
                    orderId,
                    paymentKey,
                    amount: Number(amount)
                });

                console.log('Payment Response:', response.data);

                // 성공 시 state와 함께 Result 페이지로 이동
                if (response.data.success) {
                    navigate('/orders/result', {
                        state: {
                            paymentInfo: response.data.data,
                            isPaymentComplete: true // 결제 완료 플래그 추가
                        },
                        replace: true
                    });
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                console.error('결제 처리 중 오류:', error);
                alert(error.message);
                navigate('/orders/create', { replace: true });
            }
        };

        processPayment();
    }, [navigate, searchParams]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h2>결제를 처리중입니다...</h2>
        </div>
    );
}

export default PaymentResult;