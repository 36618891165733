import React from 'react';
import {Link, useLocation} from "react-router-dom";

function CreateStep5() {
    const location = useLocation();
    console.log(location.state.memData, '::::location');

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    {location.state.memData === 1 ? (
                        // 일반회원(memData가 1)일 경우
                        <>
                            <div className="title-box">
                                <h2>회원가입</h2>
                            </div>
                            <div className="title-box">
                                <h3>회원가입 완료</h3>
                            </div>
                            <div className="text-box mt40">
                                <p className="tc">회원가입을 진심으로 축하드립니다.</p>
                            </div>
                            <div className="button-box mt40">
                                <Link to="" className="btn btn-white mr8">회원정보수정</Link>
                                <Link to="/" className="btn btn-black">쇼핑하러가기</Link>
                            </div>
                        </>
                    ) : (
                        // 도매회원(memData가 2)일 경우
                        <>
                            <div className="title-box">
                                <h2>회원가입</h2>
                            </div>
                            <div className="title-box">
                                <h3>회원가입 신청 완료</h3>
                            </div>
                            <div className="text-box mt40">
                                <p className="tc">회원가입 신청이 완료되었습니다.<br />관리자 승인 후 로그인 가능합니다.</p>
                            </div>
                            <div className="button-box mt40">
                                <Link to="/" className="btn btn-black">메인페이지로</Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}

export default CreateStep5;