import instance from "../services";
import { LOGIN_INFO_URL, TOKEN_URL } from "../../utils/urls";

// 로그인
export const loginUser = async (userid, password, autoLogin = false) => {
    try {
        console.log('로그인 요청 데이터:', {
            userid,
            password,
            keepLoginChk: autoLogin ? 'Y' : 'N'
        });

        const response = await instance.post(LOGIN_INFO_URL, {
            userid,
            password,
            keepLoginChk: autoLogin ? 'Y' : 'N',
        });

        console.log('로그인 응답:', response);
        return response;
    } catch (error) {
        console.error('로그인 실패 상세:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        throw error;
    }
};


/**
 * 토큰 갱신
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const refreshToken = async () => {
    try {
        return await instance.post(TOKEN_URL);
    } catch (error) {
        console.error(error);
        throw error;
    }
}