import React from 'react';

function IntroCompany() {
    return (
        <section>
            <div className="brand-box">
                <div className="brand-image">
                    <div className="title">
                        <strong>오톡 (O.Tok)</strong>
                        <b>[<em>O</em>yster] + [<em>Tok</em>: Talk의 라틴어]</b>
                    </div>
                    <img alt={''} src="/asset/images/img_brand.png" />
                </div>
                <div className="brand-content">
                    <div className="content-left">
                        <strong>오톡 (O.Tok)</strong>
                        <b>[<em>O</em>yster] + [<em>Tok</em>: Talk의 라틴어]</b>
                        <p>안전한 천연원료, 오이스터로 나누는 <br/>안전한 먹거리와 안전한 환경을 위한 이야기</p>
                    </div>
                    <div className="content-right">
                        <p>
                            오톡[O.Tok]은 안전한 먹거리를 섭취과정을 고민합니다.<br/>
                            오톡[O.Tok]은 안전한 먹거리의 성장환경을 고민합니다.<br/>
                            오톡[O.Tok]은 안전한 먹거리의 조리여정을 고민합니다.<br/><br/>
                            오톡[O.Tok]은 먹거리의 성장과 유통, 보관, 조리과정의 전 여정에서의
                            진짜 안전한 먹거리와 안전한 환경이 될 수 있도록 고민합니다.<br/><br/>
                            자연에서 회복하는 시간!<br/>
                            오분만 담그세요!!
                        </p>
                        <p>
                            영양소 창고, 과일을 껍질채 먹이고 싶은 엄마의 마음 <br/>
                            면역이 약해진 부모님을 위한 안전한 먹거리 <br/>
                            자연으로 돌아가는 과정까지 생각하는 마음
                        </p>
                    </div>
                </div>
            </div>
        </section>

)
}

export default IntroCompany;
