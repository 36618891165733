import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/Auth/auth';
import useUserStore from '../../store/userStore';
import { setCookie } from '../../utils/cookies';

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    userid: '',
    password: '',
    keepLoginChk: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // const handleLogin = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await loginUser(
  //       formData.userid,
  //       formData.password,
  //       formData.keepLoginChk
  //     );
  //     console.log(response, ':::response');

  //     if (response.data.code === 200) {
  //       const userInfo = response.data.data.userInfo;
  //       console.log(userInfo, '::::userInfo');
  //       updateTokens(response.data.data.access_token);
  //       updateUserInfo(response.data.data.userInfo);
  //       console.log('성공', response.data.data.userInfo);
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     console.error('로그인 실패');
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const {userid , password, autoLogin} = formData;
        const response = await loginUser(userid, password, autoLogin);

        if (response?.data?.code === 200) {
            const {access_token} = response.data.data;
            const decodedPayload = JSON.parse(atob(access_token.split('.')[1]));
            const savedEmail = localStorage.getItem('userEmail'); // 로컬스토리지에서 이메일값 받아오기

            const userInfo = {
                ...decodedPayload,
                user_name: decodedPayload.username,
                email: savedEmail || response.data.data.email
            };

            // 토큰을 30일 유효기간을 가진 쿠키로 저장
            setCookie('access_token', access_token, 30);

            if (autoLogin) {
                localStorage.setItem('autoLogin', 'true');
                localStorage.setItem('savedId', userid);
                localStorage.setItem('savedPassword', btoa(password));
            }

            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            useUserStore.getState().updateTokens(access_token);
            useUserStore.getState().updateUserInfo(userInfo);

            navigate('/');
        } else {
            // 서버에서 200이 아닌 코드를 반환한 경우
            setError(response?.data?.message || '로그인에 실패했습니다.');
        }
    } catch (error) {
        // alert 제거하고 에러 메시지만 설정
        setError(error.response?.data?.message || '아이디 또는 비밀번호를 확인해주세요.');
    }
};

  return (
    <section>
      <div className="container">
        <div className="member-box">
          <div className="title-box">
            <h2>로그인</h2>
          </div>
          <form onSubmit={handleLogin}>
            <div className="input-box">
              <input
                type="text"
                name="userid"
                placeholder="아이디"
                value={formData.userid}
                onChange={handleChange}
              />
            </div>
            <div className="input-box mt8">
              <input
                type="password"
                name="password"
                placeholder="비밀번호"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
              {error && <div className={`error_message nickname`}>{error}
            </div>}
            <div className="flex flex-vc flex-tj mt16">
              <div className="check-box">
                <input
                  type="checkbox"
                  name="keepLoginChk"
                  id="check"
                  checked={formData.keepLoginChk}
                  onChange={handleChange}
                />
                <label htmlFor="check">
                  <p>로그인 유지</p>
                </label>
              </div>
              <div className="link-box">
                <ul>
                  <li><Link to="/users/find">아이디 찾기</Link></li>
                  <li><Link to="/users/find" state={{ tab: 'password' }}>비밀번호 찾기</Link></li>
                </ul>
              </div>
            </div>
            <div className="button-box mt16">
              <button type="submit" className="btn btn-black">로그인 하기</button>
            </div>
            <div className="member-text-box mt24">
              <p>오톡이 처음이신가요? <Link to="/users/createStep1">회원가입</Link></p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login; 
