import React, { useState, useEffect } from "react";
import SidebarMenu from "../../components/SidebarMenu";
import { FAQ_TYPE_URL, FAQ_LIST_URL, FAQ_DETAIL_URL } from "../../utils/urls";
import instance from "../../services/services";

function Index() {
    const [faqTypeList, setFaqTypeList] = useState([]); // 타입 리스트
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeType, setActiveType] = useState(''); // 카테고리 선택을 위한 상태
    const [faqList, setFaqList] = useState([]); // FAQ 리스트  
    const [faqDetail, setFaqDetail] = useState([]); // FAQ 상세 
    const [searchKeyword, setSearchKeyword] = useState(''); // 검색어 상태 추가

    // 타입 리스트
    useEffect(() => {
        const fetchFaqTypeList = async () => {
            const response = await instance.post(FAQ_TYPE_URL);
            setFaqTypeList(response.data.data);
        };
        fetchFaqTypeList();
    }, []);

    // FAQ 리스트 조회 함수
    const fetchFaqList = React.useCallback(async (keyword = '') => {
        try {
            const response = await instance.post(FAQ_LIST_URL, {
                boardid: "faq",             
                board_type: activeType === '' ? '' : activeType,    
                keyword: keyword,               
                offset: 1
            });

            if (keyword) {
                const filteredList = response.data.data.data.filter(item => 
                    item.subject.toLowerCase().includes(keyword.toLowerCase()) ||
                    (item.contents && item.contents.toLowerCase().includes(keyword.toLowerCase()))
                );
                setFaqList(filteredList);
            } else {
                setFaqList(response.data.data.data);
            }
        } catch (error) {
            console.error('FAQ 리스트 로딩 실패:', error);
            setFaqList([]);
        }
    }, [activeType]);

// 카테고리 클릭 핸들러 수정
const handleTypeClick = async (typeCode) => {
    setActiveType(typeCode);
    setSearchKeyword(''); // 검색어 초기화
    try {
        const response = await instance.post(FAQ_LIST_URL, {
            boardid: "faq",             
            board_type: typeCode === '' ? '' : typeCode, // 새로운 타입코드 즉시 사용    
            keyword: '',               
            offset: 1
        });
        setFaqList(response.data.data.data);
        setActiveIndex(null); // 아코디언 상태 초기화
    } catch (error) {
        console.error('FAQ 리스트 로딩 실패:', error);
        setFaqList([]);
    }
};

    // 초기 데이터 로딩
    useEffect(() => {
        fetchFaqList();
    }, [fetchFaqList]);


    // Q&A 아코디언 클릭 핸들러
    const handleItemClick = (index, b_seq) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
        handleFaqClick(b_seq);
    };



    // FAQ 상세 조회
    const handleFaqClick = async (b_seq) => {
        try {
            const response = await instance.post(FAQ_DETAIL_URL, {
                b_seq: b_seq
            });
            console.log(response.data.data.data)
            setFaqDetail(prev => ({
                ...prev,
                [b_seq]: response.data.data.data // data.data.data에서 데이터 가져오기
            }));
        } catch(error) {
            console.error('FAQ 상세 데이터 로딩 실패:', error);
        }
    }
    // 검색어 입력 핸들러
    const handleKeywordChange = (e) => {
        setSearchKeyword(e.target.value);
    };

    // 검색 버튼 클릭 핸들러
    const handleSearch = (e) => {
        e.preventDefault();
        fetchFaqList(searchKeyword);
    };

    


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="Q&A" />
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>Q&A</h2>
                        </div>
                        <div className="mb10 mb-lg-14 flex flex-vc flex-tj">
                            <div className="category-box col-lg-12 order-lg-2 mt-lg-16">
                                <ul>
                                    <li className={activeType === '' ? 'active' : ''}>
                                            <button 
                                                type="button" 
                                                className="link"
                                                onClick={() => handleTypeClick('')}
                                            >
                                                전체
                                            </button>
                                        </li>
                                        {faqTypeList?.map((type) => (
                                            <li 
                                                key={type.codecd}
                                                className={activeType === type.codecd ? 'active' : ''}
                                            >
                                                <button 
                                                    type="button" 
                                                    className="link"
                                                    onClick={() => handleTypeClick(type.codecd)}
                                                >
                                                    {type.value}
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div className="search-box mb0 col-lg-12 order-lg-1">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <input 
                                        type="text" 
                                        placeholder="제목, 내용 검색"
                                        value={searchKeyword}
                                        onChange={handleKeywordChange}
                                    />
                                    <button 
                                        type="button" 
                                        className="search"
                                        onClick={handleSearch}
                                    >
                                        검색
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="board-qna-box">
                            <ul>
                                {faqList.map((faq, index) => (
                                    <li
                                        key={faq.b_seq}
                                        className={activeIndex === index ? "active" : ""}
                                        onClick={() => handleItemClick(index, faq.b_seq)}
                                    >
                                        <button type="button" className="inner">
                                            <b>{faq.board_type_name}</b>
                                            <div>
                                                <strong>
                                                    {faq.subject}
                                                </strong>
                                                <p>
                                                    {activeIndex === index && faqDetail[faq.b_seq]?.contents}
                                                </p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                {[2, 3, 4, 5].map((num) => (
                                    <li key={num}>
                                        <button type="button">{num}</button>
                                    </li>
                                ))}
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
