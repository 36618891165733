import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";

function CreateStep1() {
    const navigate = useNavigate();
    const [mailing, setMailing] = useState('N');
    console.log(mailing, ';::mailing');
    const [sms, setSms] = useState('N');
    console.log(sms, '::sms');

    const handleCheck = () => {
        setMailing(prev => prev === 'N' ? 'Y' : 'N'); // 클릭 시 Y/N 토글
        setSms(prev => prev === 'N' ? 'Y' : 'N'); // 클릭 시 Y/N 토글
    }

    const handleNextStep = () => {
        // 다음 페이지로 이동하면서 상태 전달
        navigate("/users/createStep3", { state: { mailing, sms } });
    }

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    <div className="title-box">
                        <h2>회원가입</h2>
                    </div>
                    <form>
                        <div className="check-total-box">
                            <div className="title-box">
                                <h3>서비스 이용약관 동의</h3>
                            </div>
                            <div className="check-total">
                                <div className="check-box">
                                    <input type="checkbox" name="check" id="check" />
                                    <label htmlFor="check"><p>전체 동의 (선택 정보 포함)</p></label>
                                </div>
                            </div>
                            <div className="check-list">
                                <div className="check-box mb16">
                                    <input type="checkbox" name="check" id="check" />
                                        <label htmlFor="check">
                                            <p>[필수] 이용약관 동의</p>
                                            <Link to="">보기</Link>
                                        </label>
                                </div>
                                <div className="check-box mb16">
                                    <input type="checkbox" name="check" id="check" />
                                        <label htmlFor="check">
                                            <p>[필수] 개인정보 수집 및 이용 동의</p>
                                            <Link to="">보기</Link>
                                        </label>
                                </div>
                                <div className="check-box" onClick={handleCheck}>
                                    <input type="checkbox" name="check" id="check" />
                                        <label htmlFor="check">
                                            <p>[선택] 쇼핑정보 수신 동의</p>
                                            <Link to="">보기</Link>
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div className="button-box mt24">
                            <button type="button" className="btn btn-black disabled" onClick={handleNextStep}>
                                    동의하고 가입하기
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
    }

export default CreateStep1;
