import React, { useState, useEffect } from 'react';
import SidebarMenu from "../../components/SidebarMenu";
import { TAX_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';
import { Link } from 'react-router-dom';
import useUserStore from '../../store/userStore';

function Index() {

    const [taxList, setTaxList] = useState([]);
    const { userInfo } = useUserStore();

    console.log('현재 로그인한 사용자:', userInfo?.userid);
    console.log('게시글 작성자:', taxList.map(tax => tax.userid));
    


    useEffect(() => {
        const fetchTaxList = async () => {
            const response = await instance.post(TAX_LIST_URL, {
                offset: 1
            });
            console.log(response.data.data.data)
            setTaxList(response.data.data.data);
        };
        fetchTaxList();
    }, []);


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="세금계산서"/>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>세금계산서</h2>
                        </div>
                        <div className="button-box flex-tr mb14">
                            <div>
                                <a href="/bill/create" className="btn btn-black sm px16 h32">
                                    문의하기
                                </a>
                            </div>
                        </div>
                        <div className="board-list-box">
                            <ul>
                                {taxList && taxList.map((tax, index) => (
                                    <li key={index}>
                                        <Link 
                                            to="/bill/show" 
                                            state={{ b_seq: tax.b_seq, taxList: tax }}
                                            className="inner"
                                            onClick={(e) => {
                                                if (tax?.userid !== userInfo?.userid) {
                                                    e.preventDefault();
                                                    alert("비밀글입니다.");
                                                }
                                            }}
                                        >
                                            <div className="list-left order-lg-2">
                                                <div className={
                                                    tax?.userid === userInfo?.userid 
                                                    ? 'subject' 
                                                    : 'subject secret' 
                                                }>
                                                    <p>{tax?.subject || '제목 없음'}</p>
                                                </div>
                                                <div className="info">
                                                    <dl>
                                                        <dd>{tax?.order_date || '-'}</dd>
                                                        <dd>{tax?.order_name ? `${tax.order_name.slice(0, 3)}${'*'.repeat(5)}` : '***'}</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className="list-right order-lg-1">
                                                <div className="state">
                                                    {tax?.re_contents ? (
                                                        <span className="active">답변완료</span>
                                                    ) : (
                                                        <span>답변대기</span>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                                
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
