import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ID_FIND_URL, PASSWORD_FIND_URL, PASSWORD_CHANGE_URL } from '../../utils/urls';
import instance from '../../services/services';

function Find() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('id');
    const [formData, setFormData] = useState({phone: ''});
    const [timeLeft, setTimeLeft] = useState(null);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [userId, setUserId] = useState('');
    const [findStatus, setFindStatus] = useState({
        show: false,
        success: false,
        userId: ''
    });
    const [popup, setPopup] = useState({
        isOpen: false,
        type: null
    });
    const [currentStep, setCurrentStep] = useState(1); // 1: 첫 화면, 2: 비밀번호 변경, 3: 완료
    const [passwordForm, setPasswordForm] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [passwordError, setPasswordError] = useState(false);


    const handlePopupOpen = (type) => {
        setPopup({
            isOpen: true,
            type: type
        });
        document.documentElement.classList.add("fixed");
        document.body.classList.add("fixed");
    };
    
    const handlePopupClose = () => {
        setPopup({
            isOpen: false,
            type: null
        });
        document.documentElement.classList.remove("fixed");
        document.body.classList.remove("fixed");
    };

    const handleIdCheck = async () => { // 아이디 찾기
        try {
            const response = await instance.post(ID_FIND_URL, {
                mem_phone: formData.phone
            });
            
            if (response.data.success) {
                setUserId(response.data.data.userid)
                setFindStatus({
                    show: true,
                    success: true,
                    userId: response.data.userId // 서버에서 받은 userId 값으로 수정
                });
            } else {
                setFindStatus({
                    show: true,
                    success: false,
                    userId: ''
                });
            }
        } catch (e) {
            setFindStatus({
                show: true,
                success: false,
                userId: ''
            });
            if (e.response?.data?.message) {
                console.error(e.response.data);
            } else {
                console.error(e);
            }
        }
    }
    const handlePasswordCheck = async () => { // 비밀번호 찾기
        try {
            const response = await instance.post(PASSWORD_FIND_URL, {
                userid: formData.userId
            });
            
            if (response.data.success) {
                setFindStatus({
                    show: true,
                    success: true,
                    userId: response.data.userId
                });
                setCurrentStep(2, formData.userId);
            } else {
                setFindStatus({
                    show: true,
                    success: false,
                    userId: ''
                });
                handlePopupOpen('popup01');
            }
        } catch (e) {
            console.error(e);
            setFindStatus({
                show: true,
                success: false,
                userId: ''
            });
            handlePopupOpen('popup01');
        }
    }

    const handlePasswordChange = async () => { // 비밀번호 변경
        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            setPasswordError(true);
            return;
        }
        
        try {
            const response = await instance.post(PASSWORD_CHANGE_URL, {
                userid: formData.userId,
                password: passwordForm.newPassword,
                repassword: passwordForm.newPassword
            });
            
            if (response.data.success) {
                setCurrentStep(3); // 완료 화면으로 이동
            } else {
                handlePopupOpen('popup01');
            }
        } catch (e) {
            console.error(e);
            handlePopupOpen('popup01');
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const startTimer = () => {
        setTimeLeft(120); // 2분 = 120초
        setIsTimerRunning(true);
    };

    const handleSendVerification = () => {
        if (formData.phone) {
            startTimer();
            // TODO: 실제 인증번호 전송 로직 추가
        } else {
            alert('휴대폰 번호를 입력해주세요.');
        }
    };

    useEffect(() => {
        if (location.state?.tab === 'password') {
            setActiveTab('password');
        }
    }, [location.state]);

    useEffect(() => {
        let interval;
        if (isTimerRunning && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsTimerRunning(false);
        }
        return () => clearInterval(interval);
    }, [isTimerRunning, timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}분 ${remainingSeconds.toString().padStart(2, '0')}초`;
    };

    return (
        <section>
            <div className="container">
                {/*승인완료*/}
                
                <div className="member-box">
                    <div className="title-box">
                        <h2>계정찾기</h2>
                    </div>
                    <div className="tab-container">
                        <div className="tab-box">
                            <ul>
                                <li className={activeTab === 'id' ? 'active' : ''}>
                                    <Link to="" onClick={() => setActiveTab('id')}>아이디</Link>
                                </li>
                                <li className={activeTab === 'password' ? 'active' : ''}>
                                    <Link to="" onClick={() => setActiveTab('password')}>비밀번호</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content-box">
                            <div className={`content ${activeTab === 'id' ? 'active' : ''}`}>
                            {!findStatus.show ? (
                                // 초기 화면 (휴대폰 인증)
                                <>
                                    <div className="text-box">
                                        <p className="tc">휴대폰 본인인증을 통해 아이디를 확인합니다.</p>
                                    </div>

                                    <li style={{marginBottom:"8px",marginTop:"40px", position: "relative"}}>
                                        <strong className="form-title">휴대폰 번호</strong>
                                        <div className="form-content">
                                            <div className="flex flex-vc" style={{marginTop:"8px"}}>
                                                <div className="input-box flex-1">
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                        placeholder="휴대폰 번호 입력"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="button-box w120" style={{
                                            position: "absolute",
                                            top: "30px",
                                            right: "12px",
                                            borderRadius: "2px",
                                            background: "#EEE",
                                            padding: "7px 0px"
                                        }}>
                                            <button type="button" onClick={handleSendVerification} style={{color:"#111", textAlign:"center", fontSize:"12px", fontWeight:"400", lineHeight:"150%"}}>
                                                인증번호 전송
                                            </button>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="form-content">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="verificationCode"
                                                    value={formData.verificationCode}
                                                    onChange={handleInputChange}
                                                    placeholder="인증번호 입력"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    {isTimerRunning && timeLeft > 0 && (
                                        <div style={{
                                            marginTop: "8px",
                                            fontSize: "13px",
                                            color: "#C62D26",
                                            background: "#F8F8F8",
                                            textAlign: "left",
                                            padding: "6px 12px",
                                        }}>
                                            {formatTime(timeLeft)}
                                        </div>
                                    )}
                                    <div className="button-box mt24">
                                        <button type="button" className="btn btn-black" onClick={handleIdCheck}>
                                            다음
                                        </button>
                                    </div>
                                </>
                            ) : findStatus.success ? (
                                // 계정 찾기 성공
                                <>
                                    <div className="text-box">
                                        <p className="tc">가입한 계정이 있습니다.</p>
                                        <small className="tc">해당 계정으로 로그인해 주세요.</small>
                                    </div>
                                    <div className="find-box mt24">
                                        <p>{userId}</p>
                                    </div>
                                    <div className="button-box mt24">
                                    <Link to="/users/find" state={{ tab: 'password' }} className="btn btn-white mr8">비밀번호 찾기</Link>
                                        <Link to="/login" className="btn btn-black">로그인</Link>
                                    </div>
                                </>
                            ) : (
                                // 계정 찾기 실패
                                <>
                                    <div className="text-box">
                                        <p className="tc">계정을 찾을 수 없습니다.</p>
                                        <small className="tc">입력하신 정보와 일치하는 계정이 없습니다.<br />정보를 다시 확인해주세요.</small>
                                    </div>
                                    <div className="button-box mt40">
                                        <button type="button" className="btn btn-white mr8" onClick={() => setFindStatus({success: false, userId: ''})}>계정 다시 찾기</button>
                                        <Link to="/users/createStep1" className="btn btn-black">회원가입하기</Link>
                                    </div>
                                </>
                            )}
                        </div>


                            {/* 비밀번호 찾기 */}
                                <div className={`content ${activeTab === 'password' ? 'active' : ''}`}>
                                    {currentStep === 1 && (
                                        <>
                                {/* 비밀번호 찾기 */}
                                <div className="text-box">
                                    <p className="tc">가입한 아이디(이메일)를 입력해주세요.</p>
                                    <small className="tc">휴대폰 본인인증을 통해 비밀번호를 변경합니다.</small>
                                </div>
                                <form className="mt40">
                                    <div className="write-box">
                                        <ul>
                                            <li>
                                                <strong className="form-title">아이디</strong>
                                                <div className="form-content">
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            placeholder="아이디 입력"
                                                            name="userId"
                                                            value={formData.userId}
                                                            onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                                <li style={{marginBottom:"8px",marginTop:"24px", position: "relative"}}>
                                        <strong className="form-title">휴대폰 번호</strong>
                                        <div className="form-content">
                                            <div className="flex flex-vc" style={{marginTop:"8px"}}>
                                                <div className="input-box flex-1">
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                        placeholder="휴대폰 번호 입력"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="button-box w120" style={{
                                            position: "absolute",
                                            top: "30px",
                                            right: "12px",
                                            borderRadius: "2px",
                                            background: "#EEE",
                                            padding: "7px 0px"
                                        }}>
                                            <button type="button" onClick={handleSendVerification} style={{color:"#111", textAlign:"center", fontSize:"12px", fontWeight:"400", lineHeight:"150%"}}>
                                                인증번호 전송
                                            </button>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="form-content">
                                            <div className="input-box">
                                                <input
                                                    type="text"
                                                    name="verificationCode"
                                                    value={formData.verificationCode}
                                                    onChange={handleInputChange}
                                                    placeholder="인증번호 입력"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    {isTimerRunning && timeLeft > 0 && (
                                        <div style={{
                                            marginTop: "8px",
                                            fontSize: "13px",
                                            color: "#C62D26",
                                            background: "#F8F8F8",
                                            textAlign: "left",
                                            padding: "6px 12px",
                                        }}>
                                            {formatTime(timeLeft)}
                                        </div>
                                    )}
                                <div className="button-box mt24">
                                    <button className="btn btn-black" onClick={handlePasswordCheck}>다음</button>
                                </div>
                            </>
                        )}

                                {currentStep === 2 && (
                                    <>
                                        <div className="text-box">
                                            <p className="tc">영문소문자/숫자/특수문자 포함 8~16자의 <br />새로운 비밀번호를 입력해 주세요.</p>
                                        </div>
                                        <form className="mt40">
                                            <div className="write-box">
                                                <ul>
                                                    <li>
                                                        <strong className="form-title">새 비밀번호</strong>
                                                        <div className="form-content">
                                                            <div className="input-box">
                                                                <input 
                                                                    type="password" 
                                                                    placeholder="새 비밀번호 입력"
                                                                    value={passwordForm.newPassword}
                                                                    onChange={(e) => {
                                                                        setPasswordForm({
                                                                            ...passwordForm,
                                                                            newPassword: e.target.value
                                                                        });
                                                                        setPasswordError(false);
                                                                        setFormData({
                                                                            ...formData,
                                                                            newPassword: e.target.value
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <strong className="form-title">새 비밀번호 확인</strong>
                                                        <div className="form-content">
                                                            <div className="input-box">
                                                                <input 
                                                                    type="password" 
                                                                    placeholder="새 비밀번호를 한번 더 입력"
                                                                    value={passwordForm.confirmPassword}
                                                                    onChange={(e) => {
                                                                        setPasswordForm({
                                                                            ...passwordForm,
                                                                            confirmPassword: e.target.value
                                                                        });
                                                                        setPasswordError(false);
                                                                        setFormData({
                                                                            ...formData,
                                                                            confirmPassword: e.target.value
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            {passwordError && (
                                                                <div className="message-box">
                                                                    <p className="error">비밀번호 정보가 일치하지 않습니다.</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                        <div className="button-box mt24">
                                            <button onClick={handlePasswordChange} className="btn btn-black">확인</button>
                                        </div>
                                    </>
                                )}

                                {currentStep === 3 && (
                                    <>
                                        <div className="text-box">
                                            <p className="tc">비밀번호 변경이 완료되었습니다.</p>
                                        </div>
                                        <div className="button-box mt40">
                                            <Link to="/login" className="btn btn-black">로그인 페이지로</Link>
                                        </div>
                                    </>
                                )}

                                {/* 팝업창 */}
                                {popup.isOpen && (
                                    <div className={`popup-box ${popup.isOpen ? 'fixed' : ''}`}>
                                        <div className={`box sm active`} data-name={popup.type}>
                                            <div className="text-box">
                                                <p className="tc">등록된 아이디가 없습니다.<br />다시 확인해주세요.</p>
                                            </div>
                                            <div className="button-box mt32">
                                                <div className="w135">
                                                    <Link to="" onClick={handlePopupClose} className="btn btn-white f13">확인</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Find;
