import { ORDER_REQUEST_URL } from "../../utils/urls";
import instance from "../services";

export const orderRequest = async (orderData) => {
    try {
        // 데이터 유효성 검사
        if (!orderData || !orderData.delivery) {
            throw new Error('주문 데이터가 올바르지 않습니다.');
        }

        const response = await instance.post(ORDER_REQUEST_URL, orderData);
        console.log(response.data.data.data, '::::response결제!!');
        
        // response 데이터 구조 확인
        if (!response.data) {
            throw new Error('서버 응답이 올바르지 않습니다.');
        }

        // 응답 데이터 로깅
        console.log('주문 요청 응답:', orderData);
        
        return {
            data: {
                settlePrice: orderData.settlePrice,
                orderId: response.data.data.data.orderId,
                orderName: response.data.data.data.orderName
            }
        };
    } catch (error) {
        console.error('주문 요청 에러:', error);
        throw error;
    }
};