import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../components/MypageSidebarMenu';

function Create() {


    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"1:1문의"} />
                    <div className="sub-right">
                        <div className="title-box mb14">
                            <h2 className="tl f18">1:1문의</h2>
                        </div>
                        <div className="board-write-box">
                            <ul>
                                <li>
                                    <strong>아이디</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="input-box">
                                                <input type="text" className="h40" value="canksh77" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>문의유형</strong>
                                    <div>
                                        <div className="w450 col-lg-12">
                                            <div className="select-box">
                                                <select className="h40">
                                                    <option>문의유형 선택</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>제목</strong>
                                    <div>
                                        <div className="input-box">
                                            <input
                                                type="text"
                                                placeholder="제목을 입력해 주세요."
                                                className="h40"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>내용</strong>
                                    <div>
                                        <div className="textarea-box lg">
                                            <textarea placeholder="내용을 입력해 주세요."></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>첨부파일</strong>
                                    <div>
                                        <div className="file-image-box">
                                            <ul className="file-list">
                                                <li>
                                                    <label htmlFor="file">
                                                        파일찾기
                                                        <input type="file" name="file" id="file" multiple />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="message-box">
                                            <p className="error">- 파일당 최대 10MB 의 용량 제한이 있습니다.</p>
                                            <p className="ft-lightgray">- 최대 5개까지 등록 가능합니다.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="button-box mt32">
                            <div className="w200 flex-lg-1 mr8">
                                <Link to="/mypage/qa" className="btn btn-white">
                                    취소
                                </Link>
                            </div>
                            <div className="w200 flex-lg-1">
                                <Link to="#" className="btn btn-black">
                                    등록
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Create;
