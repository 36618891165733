import React from 'react';
import { Link } from 'react-router-dom';
import MypageSidebarMenu from '../../../../components/MypageSidebarMenu';


function Index() {

    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <MypageSidebarMenu activeItem={"구매후기"} />
                    <div className="sub-right">
                        <div className="tab-box mb0">
                            <ul>
                                <li>
                                    <Link to="/mypage/review/able" className="link">
                                        작성 가능한 후기<em>13</em>
                                    </Link>
                                </li>
                                <li className="active">
                                    <Link to="/mypage/review/my" className="link">
                                        내 후기<em>24</em>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="review-list-my-box">
                            <ul>
                                {[1, 2].map((_, index) => (
                                    <li key={index}>
                                        <div className="list-left">
                                            <div className="left-item">
                                                <img
                                                    src="/asset/images/img_item_list_01.png"
                                                    alt="상품 이미지"
                                                />
                                                <div className="inner">
                                                    <b>O.Tok</b>
                                                    <p>국내최초 천연 산호칼슘 과일야채 세정제</p>
                                                    <small>
                                                        옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제
                                                    </small>
                                                    <small>수량: 1개</small>
                                                </div>
                                            </div>
                                            <div className="left-review">
                                                <div className="left-review-top">
                                                    <div className="score-box">
                                                        <span className="active">별점</span>
                                                        <span className="active">별점</span>
                                                        <span className="active">별점</span>
                                                        <span className="active">별점</span>
                                                        <span>별점</span>
                                                    </div>
                                                    <time>2024.09.26</time>
                                                </div>
                                                <div className="left-review-content">
                                                    <p>향도 은은하고 사용 후 과일 맛에도 변화가 없어요.</p>
                                                    <dl>
                                                        <dd>
                                                            <img
                                                                src="/asset/images/img_review_thumb_01.png"
                                                                alt="리뷰 이미지"
                                                            />
                                                        </dd>
                                                        <dd>
                                                            <img
                                                                src="/asset/images/img_review_thumb_02.png"
                                                                alt="리뷰 이미지"
                                                            />
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-right">
                                            <div className="button-box">
                                                <Link to="#" className="btn btn-white sm h32 mr4">
                                                    수정
                                                </Link>
                                                <Link to="#" className="btn btn-white sm h32">
                                                    삭제
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="paging-box">
                            <ul>
                                <li className="first">
                                    <button type="button">처음</button>
                                </li>
                                <li className="prev">
                                    <button type="button">이전</button>
                                </li>
                                <li className="active">
                                    <button type="button">1</button>
                                </li>
                                <li>
                                    <button type="button">2</button>
                                </li>
                                <li>
                                    <button type="button">3</button>
                                </li>
                                <li>
                                    <button type="button">4</button>
                                </li>
                                <li>
                                    <button type="button">5</button>
                                </li>
                                <li className="next">
                                    <button type="button">다음</button>
                                </li>
                                <li className="last">
                                    <button type="button">맨끝</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
