import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './pages/Main/Main';
import Login from './pages/Login/Login';
import Find from "./pages/Users/Find";
import UsersCreateStep1 from "./pages/Users/CreateStep1";
import UsersCreateStep2 from "./pages/Users/CreateStep2";
import UsersCreateStep3 from "./pages/Users/CreateStep3";
import UsersCreateStep4 from "./pages/Users/CreateStep4";
import UsersCreateStep5 from "./pages/Users/CreateStep5";
import CartsIndex from './pages/Carts/Index';
import OrdersCreate from './pages/Orders/Create';
import OrdersResult from './pages/Orders/Result';
import ProductsIndex from './pages/Products/Index';
import ProductsShow from './pages/Products/Show';
import IntroCompany from "./pages/Contents/Brands/IntroCompany";
import useUserStore from './store/userStore';
import BenefitIndex from './pages/Benefit/Index'

import EventsIndex from './pages/Events/Index'

import CscenterIndex from './pages/Cscenter/Index'
import NoticeIndex from './pages/Notice/Index'
import NoticeShow from './pages/Notice/Show'
import QnaIndex from './pages/Qna/Index'
import BillIndex from './pages/Bill/Index'
import BillShow from './pages/Bill/Show'
import BillCreate from './pages/Bill/Create'
import IntroIndex from './pages/Intro/Index'
import LocationIndex from './pages/Location/Index'
import GuideIndex from './pages/Guide/Index'
import PaymentResult from './pages/Payment/PaymentResult';

import MypageBillIndex from './pages/Mypage/Bill/Index'
import MypageBillCreate from './pages/Mypage/Bill/Create'

import MypageReviewAbleIndex from './pages/Mypage/Review/Able/Index'
import MypageReviewMyIndex from './pages/Mypage/Review/My/Index'
import MypageReviewCreate from './pages/Mypage/Review/Create'

import MypageQAproductIndex from './pages/Mypage/QAproduct/Index'

import MypageQAIndex from './pages/Mypage/QA/Index'
import MypageQACreate from './pages/Mypage/QA/Create'

import { getCookie, removeCookie } from './utils/cookies';

function App() {
  const { updateUserInfo, memberTokens } = useUserStore();

  useEffect(() => {
    const initializeAuth = () => {
        const accessToken = getCookie('access_token');
        const storedUserInfo = localStorage.getItem('userInfo');

        if (accessToken && storedUserInfo) {
            try {
              const parsedUserInfo = JSON.parse(storedUserInfo);
              updateUserInfo(parsedUserInfo);
              useUserStore.getState().updateTokens(accessToken); // memberTokens 업데이트 추가
            } catch (error) {
                console.error('사용자 정보 파싱 에러:', error);
                removeCookie('access_token');
                localStorage.removeItem('userInfo');
            }
        }
    };

    initializeAuth();
}, [updateUserInfo]);
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path='/' element={<Main />}/>

          {/*브랜드*/}
          <Route path='/brands/introCompany' element={<IntroCompany />}/>

          {/*로그인*/}
          <Route path='/login' element={memberTokens ? <Navigate to="/" replace /> : <Login />}/>

          {/*아이디 비번찾기*/}
          <Route path='/users/find' element={<Find />}/>

          {/*회원가입*/}
          <Route path='/users/createStep1' element={<UsersCreateStep1 />}/>
          <Route path='/users/createStep2' element={<UsersCreateStep2 />}/>
          <Route path='/users/createStep3' element={<UsersCreateStep3 />}/>
          <Route path='/users/createStep4' element={<UsersCreateStep4 />}/>
          <Route path='/users/createStep5' element={<UsersCreateStep5 />}/>

          {/*장바구니*/}
          <Route path='/carts' element={<CartsIndex />}/>

          {/*결제*/}
          <Route path='/orders/create' element={<OrdersCreate />}/>
          <Route path='/orders/result' element={<OrdersResult />}/>

          {/*상품*/}
          <Route path='/products' element={<ProductsIndex />}/>
          <Route path='/products/show' element={<ProductsShow />}/>

          {/* 이벤트 */}
          <Route path='/events' element={<EventsIndex />}/>

          {/* cscenter */}
          <Route path='/cscenter' element={<CscenterIndex />}/>
          {/* 공지사항 */}
          <Route path='/notice' element={<NoticeIndex />}/>
          <Route path='/notice/show' element={<NoticeShow />}/>
          {/* qna */}
          <Route path='/qna' element={<QnaIndex />}/>
          {/* 세금계산서 */}
          <Route path='/bill' element={<BillIndex />}/>
          <Route path='/bill/create' element={<BillCreate />}/>
          <Route path='/bill/show' element={<BillShow />}/>
          {/* 회사소개 */}
          <Route path='/intro' element={<IntroIndex />}/>
          {/* 오시는길 */}
          <Route path='/location' element={<LocationIndex />}/>
          {/* 회원회택 */}
          <Route path='/benefit' element={<BenefitIndex />}/>
          {/* 이용안내 */}
          <Route path='/guide' element={<GuideIndex />}/>

          <Route path='/payment/result' element={<PaymentResult />}></Route>
          
          
          {/* 마이페이지======================= */}
          {/* 세금계산서 */}
          <Route path='/mypage/bill' element={<MypageBillIndex />}/>
          <Route path='/mypage/bill/create' element={<MypageBillCreate />}/>

          {/* 구매후기 */}
          <Route path='/mypage/review/able' element={<MypageReviewAbleIndex />}/>
          <Route path='/mypage/review/my' element={<MypageReviewMyIndex />}/>
          <Route path='/mypage/review/create' element={<MypageReviewCreate />}/>

          {/* 상품문의 */}
          <Route path='/mypage/QA-product' element={<MypageQAproductIndex />}/>
          {/* 1:1문의 */}
          <Route path='/mypage/qa' element={<MypageQAIndex />}/>
          <Route path='/mypage/qa/create' element={<MypageQACreate />}/>
          
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
