import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

function CreateStep2() {
    const location = useLocation();
    console.log(location, '::::location');
    const navigate = useNavigate();
    const mailing = location.state.mailing;
    const sms = location.state.sms;

    const handleNextStep = () => {
        // 다음 페이지로 이동하면서 상태 전달
        navigate("/users/createStep3", { state: { mailing, sms } });
    }

    return (
        <section>
            <div className="container">
                <div className="member-box">
                    <div className="title-box">
                        <h2>회원가입</h2>
                    </div>
                    <div className="title-box">
                        <h3>휴대폰 인증</h3>
                    </div>
                    <div className="text-box mt40">
                        <p className="tc">안전한 거래를 위해 딱 한번 <br />본인인증을 진행해주세요.</p>
                    </div>
                    <div className="button-box mt40">
                        <button className="btn btn-black" onClick={handleNextStep}>본인 인증하기</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateStep2;
