import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

function PopupInquiry({ 
    isPopup, 
    setIsPopup, 
    loadInquiryTypeList,
    userInfo,
    isSecret,
    setIsSecret,
    inquiryType,
    setInquiryType,
    inquiryTypeList,
    inquiryContent,
    setInquiryContent,
    handleCreateInquiry
}) {

    const location = useLocation();

    const [popupActive,setPopupActive] = useState()

    useEffect(() => {
        const body = document.body;

        // 팝업 상태에 따른 overflow 설정
        body.style.overflow = isPopup ? 'hidden' : 'auto';

        // 팝업 상태일 때만 문의 타입 리스트 로드
        if (isPopup) {
            loadInquiryTypeList();
        }

        // 컴포넌트 언마운트 시 overflow 초기화
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isPopup, loadInquiryTypeList]);

    useEffect(() => {
        let timeout;
        if (isPopup) {
            timeout = setTimeout(() => {
                setPopupActive(true);
            }, 100);
        } else {
            setPopupActive(false);
        }

        // 타이머 클린업
        return () => clearTimeout(timeout);
    }, [isPopup]);

    useEffect(() => {
        // 페이지 경로(location.pathname)가 변경될 때 overflow 초기화
        document.body.style.overflow = 'auto';
    }, [location]);



    if (isPopup)
        return (
            <div className='popup-box-component'>
                <div className={`box basic md ${popupActive ? 'active' : ''}`}>
                    <div className="popup-head">
                        <button type="button" onClick={() => setIsPopup(false)} className="close">닫기</button>
                    </div>

                    <div className="popup-body">
                        <div className="write-list-box">
                            <h3>상품문의</h3>
                            <ul>
                                <li>
                                    <strong className="form-title">아이디</strong>
                                    <div className="form-content">
                                        <div className="flex flex-vc">
                                            <div className="input-box w450 col-lg-12">
                                                <input type="text" placeholder="" value={userInfo.userid} readOnly />
                                            </div>
                                            <div className="check-box ml24 ml-lg-0 mt-lg-10">
                                                <input
                                                    type="checkbox"
                                                    name="check"
                                                    id="check"
                                                    checked={isSecret}
                                                    onChange={(e) => setIsSecret(e.target.checked)}
                                                />
                                                <label htmlFor="check"><p>비밀글</p></label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">문의유형</strong>
                                    <div className="form-content flex flex-vc">
                                        <div className="select-box w450 flex-lg-1">
                                            <select
                                                value={inquiryType}
                                                onChange={(e) => setInquiryType(e.target.value)}>
                                                {inquiryTypeList.map((type) => (
                                                    <option
                                                        key={type.code}
                                                        value={type.code}
                                                    >
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong className="form-title">내용</strong>
                                    <div className="form-content">
                                        <div className="textarea-box">
                                            <textarea
                                                placeholder="내용을 입력해 주세요."
                                                value={inquiryContent}
                                                onChange={(e) => setInquiryContent(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="guide-text-box mt24">
                            <strong>안내사항</strong>
                            <p>교환, 반품, 취소는 1:1문의를 통해 접수 부탁드립니다.</p>
                            <p>상품 및 상품 구매 과정과 관련 없는 비방, 욕설, 명예훼손성 게시글 및 상품과 관련 없는 광고글 등 부적절한 게시글 등록 시 게시글이 삭제 조치 될 수
                                있습니다.</p>
                            <p>전화번호, 이메일 등 개인 정보가 포함된 글 작성이 필요한 경우 판매자만 볼 수 있도록 비밀글로 문의해 주시기 바랍니다.</p>
                        </div>
                    </div>
                    <div className="popup-foot">
                        <div className="button-box">
                            <button className="btn btn-black f13" onClick={handleCreateInquiry}>문의하기</button>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default PopupInquiry;
