import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { PRODUCT_LISTPAGE_URL, PRODUCT_REVIEW_URL } from '../../utils/urls';
import instance from '../../services/services';
import useUserStore from '../../store/userStore';
import { formatLocalString } from '../../utils/validation';

function Index() {
    const { userInfo } = useUserStore();
    const [productList, setProductList] = useState([]);
    const [searchSort, setSearchSort] = useState("regist_date");
    
    const handleLikeClick = async (event, productSeq) => {
        event.stopPropagation();
        event.preventDefault();

        try {
            // 서버에 좋아요 상태 업데이트 요청
            const response = await instance.post(PRODUCT_REVIEW_URL, {
                member_seq: userInfo.member_seq,
                goods_seq: productSeq,
            });

            if (response.data.success) {
                // UI 업데이트
                event.target.classList.toggle('active');
                // 좋아요 상태 반영
                setProductList(prevProductList =>
                    prevProductList.map(product =>
                        product.seq === productSeq
                            ? { ...product, liked: !product.liked }
                            : product
                    )
                );
            }
        } catch (error) {
            console.error("좋아요 처리 오류:", error);
        }
    };


    useEffect(() => {
        const fetchMainData = async () => {
            try {
                const response = await instance.post(PRODUCT_LISTPAGE_URL, {
                    member_seq: userInfo.member_seq,
                    searchSort: searchSort,  // searchSort 값 사용
                    offset: 1
                });
                console.log(response.data.data.data, "::response");

                // 응답 데이터 상태에 저장
                setProductList(response.data.data.data);
            } catch (error) {
                console.error('메인 페이지 데이터 로딩 실패:', error);
            }
        };

        // fetchMainData 실행 (searchSort가 변경될 때마다)
        fetchMainData();
    }, [searchSort, userInfo.member_seq]);  // 의존성 배열에 searchSort 추가

    // 셀렉트 박스 값 변경 시 호출되는 함수
    const handleSelectChange = (event) => {
        setSearchSort(event.target.value);  // 선택된 값을 searchSort에 저장
    };
    
    return (
        <section>
            <div className="container">
                <div className="item-list-box">
                    <div className="list-head">
                        <p>총 <b>{productList.length}</b>개</p>
                            <select value={searchSort} onChange={handleSelectChange}>
                                <option value="regist_date">신상품</option>
                                <option value="low_price">낮은가격순</option>
                                <option value="high_price">높은가격순</option>
                                <option value="page_view">조회수</option>
                            </select>
                    </div>

                    <div className="list-body">
                        <ul>
                        {productList.map((product, index) => (
                            <li key={index}>
                                <Link to={`/products/show`} state={{ goods_seq: product.goods_seq }} className="inner">
                                    <div className="list-image" style={{ backgroundImage: `url(${product.goods_image?.[0] || '/asset/images/img_item_list_01.png'})` }}>
                                    {product.best_label === "Y" && (
                                        <div className="rank">
                                            <b>BEST</b>
                                            <strong>{index + 1}</strong>
                                        </div>
                                    )}
                                        <button className="link">링크</button>
                                        <div className="like">
                                            <button type="button" onClick={(e) => handleLikeClick(e, product.goods_seq)}>좋아요</button>
                                        </div>
                                    </div>
                                    <div className="list-content">
                                        <div>
                                            <div className="subject">
                                                <b>Otok</b>
                                                <p>{product?.goods_name || "설명 없음"}</p>
                                            </div>
                                            <div className="price">
                                                <small>
                                                    <strike>{formatLocalString(product?.consumer_price || "가격 없음")}</strike>원
                                                </small>
                                                <div>
                                                    <strong>{product?.sale_percent || 0}%</strong>
                                                    <p>{formatLocalString(product?.price || 0)}<em>원</em></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        ))}
                            
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
    }

export default Index;
