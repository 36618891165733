export const ERROR_MESSAGE = {
    LENGTH_LIMIT : "자 를 초과할수 없습니다.",
    EMAIL_DUPLICATE : "이미 사용중인 이메일 입니다.",
    EMAIL_FORMAT: "올바른 형식의 이메일을 입력해 주세요.",
    PASSWORD : "영문소문자/숫자/특수문자 포함 8~16자로 입력해 주세요.",
    PASSWORD_NOMATCH : "비밀번호가 일치하지 않습니다",
    NICKNAME_EDIT : "닉네임을 2~10자로 입력해 주세요.", 
    NICKNAME_EXISTENCE : "닉네임이 이미 존재합니다.",
    ID_EXISTENCT : "이미 사용중인 아이디 입니다.",
    EMAIL_EXISTENCT : "이미 사용중인 이메일 입니다.",
    ID : "영문소문자/숫자 포함 4~16자로 입력해 주세요.",
}   
export const SUCCES_MESSAGE = {
    INPUT : "정상입력 입니다.", 
    PASSWORD : "사용 가능한 비밀번호 입니다.",
    REGISTER : "회원가입이 완료되었습니다. 로그인 후 이용이 가능합니다." , 
}
export const STATUS = {
    SUCCESS : "success",
    ERROR : "error"
}
export const PAYMENT_STATUS = {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    PENDING: 'PENDING',
};

export const PAYMENT_ERROR_MESSAGES = {
    USER_CANCEL: '결제가 취소되었습니다.',
    INVALID_CARD: '유효하지 않은 카드입니다.',
    INSUFFICIENT_BALANCE: '잔액이 부족합니다.',
    DEFAULT: '결제 처리 중 오류가 발생했습니다.',
};
export const HTTP_STATUS = {
    OK : 200,
    CREATED : 201,
    BAD_REQUEST : 400,
    UNAUTHORIZED : 401,
    FORBIDDEN : 403,
    NOT_FOUND : 404,
    INTERNAL_SERVER_ERROR : 500,
}