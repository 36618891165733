import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { formatLocalString } from '../../utils/validation';
import { CART_LIST_URL } from '../../utils/urls';
import instance from '../../services/services';

function Index() {
    const navigate = useNavigate();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [cartList, setCartList] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    // 수량 증가 함수 수정
    const handleIncrease = async (index) => {
        const newCartList = [...cartList];
        const currentItem = newCartList[index];
        const currentEa = parseInt(currentItem.ea || 1, 10);
        const newQuantity = currentEa + 1;
        
        try {
            await handleQuantityChange(currentItem.cart_seq, newQuantity);  // cart_id -> cart_seq
            
            // 즉각적인 UI 업데이트
            currentItem.ea = newQuantity;
            setCartList(newCartList);
        } catch (error) {
            console.error('수량 증가 실패:', error);
        }
    };


    // 수량 감소 함수 수정
    const handleDecrease = async (index) => {
        const newCartList = [...cartList];
        const currentItem = newCartList[index];
        
        if (currentItem.ea > 1) {
            const newQuantity = currentItem.ea - 1;
            
            try {
                await handleQuantityChange(currentItem.cart_seq, newQuantity);  // cart_id -> cart_seq
                
                // 즉각적인 UI 업데이트
                currentItem.ea = newQuantity;
                setCartList(newCartList);
            } catch (error) {
                console.error('수량 감소 실패:', error);
            }
        }
    };


    // 전체 선택 핸들러 수정
    const handleAllSelect = async () => {
        const newAllSelected = !isAllSelected;
        setIsAllSelected(newAllSelected);
        
        let newSelectedItems = [];
        if (newAllSelected) {
            // 전체 선택 시 모든 인덱스를 selectedItems에 추가
            newSelectedItems = Array.from({ length: cartList.length }, (_, index) => index);
        }
        setSelectedItems(newSelectedItems);

        // 선택된 상품들의 cart_seq를 이용하여 API 호출
        const selectedCartSeqs = newSelectedItems
            .map(index => cartList[index]?.cart_seq)
            .filter(seq => seq)
            .join(',');

        // API 호출하여 금액 정보 업데이트
        try {
            const response = await instance.post(CART_LIST_URL, {
                cart_change_goods: [],
                pay_select_goods: selectedCartSeqs
            });
            setTotalPrice(response.data.data.payInfo);
        } catch (error) {
            console.error('금액 정보 업데이트 실패:', error);
        }
    };

    // 개별 아이템 선택 핸들러 수정
    const handleItemSelect = async (index) => {
        const newSelectedItems = [...selectedItems];
        const itemIndex = newSelectedItems.indexOf(index);
        
        if (itemIndex > -1) {
            // 이미 선택된 아이템이면 제거
            newSelectedItems.splice(itemIndex, 1);
        } else {
            // 선택되지 않은 아이템이면 추가
            newSelectedItems.push(index);
        }
        setSelectedItems(newSelectedItems);
        
        // 모든 아이템이 선택되었는지 확인
        setIsAllSelected(newSelectedItems.length === cartList.length);

        // 선택된 상품들의 cart_seq를 이용하여 API 호출
        const selectedCartSeqs = newSelectedItems
            .map(idx => cartList[idx]?.cart_seq)
            .filter(seq => seq)
            .join(',');

        // API 호출하여 금액 정보 업데이트
        try {
            const response = await instance.post(CART_LIST_URL, {
                cart_change_goods: [],
                pay_select_goods: selectedCartSeqs
            });
            setTotalPrice(response.data.data.payInfo);
        } catch (error) {
            console.error('금액 정보 업데이트 실패:', error);
        }
    };
    // API 호출 함수
    const fetchCartList = async (cartChangeGoods = [], paySelectGoods = '') => {
        try {
            const response = await instance.post(CART_LIST_URL, {
                cart_change_goods: cartChangeGoods,
                pay_select_goods: paySelectGoods
            });
            console.log(response.data.data.payInfo)
            setTotalPrice(response.data.data.payInfo)
            setCartList(response.data.data.cartList.data);
        } catch (error) {
            console.error('장바구니 조회 실패:', error);
        }
    };

    // 수량 변경 시 API 호출 함수 수정
    const handleQuantityChange = async (cartSeq, newQuantity) => {
        const cartChangeGoods = [{
            cart_seq: cartSeq, 
            ea: newQuantity,
            delete_flag: 'N',
        }];
        await fetchCartList(cartChangeGoods);
    };


    // 초기 장바구니 목록 조회
    useEffect(() => {
        fetchCartList();
    }, []);

    // 선택된 상품 삭제 핸들러
    const handleDeleteSelected = async () => {
        try {
            const cartChangeGoods = selectedItems
                .map(index => ({
                    cart_seq: cartList[index]?.cart_seq,
                    ea: cartList[index]?.ea,
                    delete_flag: "Y"  // 선택 삭제 시에도 Y
                }))
                .filter(item => item.cart_seq && item.ea);

            if (cartChangeGoods.length > 0) {
                await fetchCartList(cartChangeGoods, '');
                setSelectedItems([]);
                setIsAllSelected(false);
            }
        } catch (error) {
            console.error('선택 상품 삭제 실패:', error);
        }
    };

    // 개별 상품 삭제 핸들러
    const handleDeleteItem = async (cartSeq, ea) => {
        try {
            if (!cartSeq) {
                console.error('cart_seq가 없습니다');
                return;
            }

            const cartChangeGoods = [{
                cart_seq: cartSeq,
                ea: ea || "1",
                delete_flag: "Y"  // 삭제 시에는 Y
            }];
            
            await fetchCartList(cartChangeGoods, '');
        } catch (error) {
            console.error('상품 삭제 실패:', error);
        }
    };

    // 선택상품 주문하기 클릭
    const handleOrderSelected = async () => {
        try {
            const selectedCartSeqs = selectedItems
                .map(index => cartList[index]?.cart_seq)
                .filter(cartSeq => cartSeq)  // 유효한 cart_seq만 포함
                .join(',');

        const response = await instance.post(CART_LIST_URL, {
            cart_change_goods: [],
            pay_select_goods: selectedCartSeqs
        });

            
            navigate('/orders/create',{
                state: response.data.data
            });
        } catch (error) {
            console.error('장바구니 조회 실패:', error);
        }
    };
    

    return (
        <section>
            <div className="container">
                <div className="title-box mb40 mb-lg-24">
                    <h2>장바구니</h2>
                </div>
                <div className="item-cart-box">
                    <div className="cart-left">
                        <div className="cart-head">
                            <div className="check-box f0">
                                <input
                                    type="checkbox" 
                                    name="check" 
                                    id="checkAll"  
                                    checked={isAllSelected}
                                    onChange={handleAllSelect}/>
                                <label htmlFor="checkAll"><p>전체 선택</p></label>
                            </div>
                        </div>

                        <div className="cart-body">
                            {cartList?.length === 0 ? (
                                <p>장바구니가 비어 있습니다.</p>
                            ) : (
                                <ul>
                                    {cartList?.map((item, index) => (
                                        <li key={item.cart_id || index}>
                                            <div className="check">
                                                <div className="check-box f0">
                                                    <input 
                                                        type="checkbox" 
                                                        name={`check${index}`} 
                                                        id={`check${index}`}
                                                        checked={selectedItems.includes(index) || isAllSelected}
                                                        onChange={() => handleItemSelect(index)}
                                                    />
                                                    <label htmlFor={`check${index}`}><p>선택</p></label>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="inner">
                                                    <div className="list-image" style={{ backgroundImage: `url(${item.goods_image})` }}></div>
                                                    <div className="list-content">
                                                        <div className="subject">
                                                            <b>O.Tok</b>
                                                            <p>{item?.goods_name}</p>
                                                            {/* <small>옵션: [O.Tok]국내최초 천연 산호칼슘 과일야채 세정제</small> */}
                                                        </div>
                                                        <div className="price">
                                                            <small>
                                                                <strike>
                                                                    {item?.consumer_price && formatLocalString(item.consumer_price)}원
                                                                </strike>
                                                            </small>
                                                            <div>
                                                                <strong>{item?.sale_percent}%</strong>
                                                                <p>
                                                                    {item?.price && formatLocalString(item.price)}<em>원</em>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="quantity">
                                                            <div className="quantity-box">
                                                                <button 
                                                                    type="button" 
                                                                    className="minus" 
                                                                    onClick={() => handleDecrease(index)}
                                                                >
                                                                    감소
                                                                </button>
                                                                <input 
                                                                    type="text" 
                                                                    value={item?.ea || 1} 
                                                                    readOnly 
                                                                />
                                                                <button 
                                                                    type="button" 
                                                                    className="plus" 
                                                                    onClick={() => handleIncrease(index)}
                                                                >
                                                                    증가
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cell">
                                                <p>배송비 별도</p>
                                            </div>
                                            <div className="cell">
                                                <p>
                                                    <b>
                                                        {formatLocalString(item?.price * (item?.ea || 1))}
                                                    </b>원
                                                </p>
                                            </div>
                                            <div className="cell">
                                                <div className="button-box">
                                                    <button className="btn btn-white"  onClick={() => handleDeleteItem(item?.cart_seq, item?.ea)} >
                                                        삭제
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>


                        <div className="cart-foot">
                            <div className="button-box" onClick={handleDeleteSelected}>
                                <button className="btn btn-white">선택상품 삭제</button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="cart-right">
                        <ul>
                            <li>
                                <p>총 상품금액</p>
                                <p>{totalPrice?.sumGoodsPrice ? formatLocalString(totalPrice.sumGoodsPrice) : 0}<em>원</em></p>
                            </li>
                            <li className="border">
                                <p>배송비</p>
                                <p>+{totalPrice?.delivery_price ? formatLocalString(totalPrice.delivery_price) : 0}<em>원</em></p>
                            </li>
                            <li>
                                <b>총 결제금액</b>
                                <b>
                                    {formatLocalString(
                                        (totalPrice?.sumGoodsPrice || 0) + (totalPrice?.delivery_price || 0)
                                    )}<em>원</em>
                                </b>
                            </li>
                            <li>
                                <small>적립예정 포인트</small>
                                <small>{totalPrice?.reserve ? formatLocalString(totalPrice.reserve) : 0}<em>p</em></small>
                            </li>
                        </ul>
                        <div className="button-box">
                            <Link  className="btn btn-active f13" onClick={handleOrderSelected}>선택상품 주문하기</Link>
                        </div>
                    </div>
                    <div className="cart-full">
                        <dl>
                            <dt>장바구니 이용안내</dt>
                            <dd>
                                <em>1.</em>
                                <p>해외배송 상품과 국내배송 상품은 함께 결제하실 수 없으니 장바구니 별로 따로 결제해 주시기 바랍니다.</p>
                            </dd>
                            <dd>
                                <em>2.</em>
                                <p>해외배송 가능 상품의 경우 국내배송 장바구니에 담았다가 해외배송 장바구니로 이동하여 결제하실 수 있습니다.</p>
                            </dd>
                            <dd>
                                <em>3.</em>
                                <p>파일첨부 옵션은 동일상품을 장바구니에 추가할 경우 마지막에 업로드 한 파일로 교체됩니다.</p>
                            </dd>
                        </dl>
                        <dl>
                            <dt>무이자할부 ��용안내</dt>
                            <dd>
                                <em>1.</em>
                                <p>해외배송 상품과 국내배송 상품은 함께 결제하실 수 없으니 장바구니 별로 따로 결제해 주시기 바랍니다.</p>
                            </dd>
                            <dd>
                                <em>2.</em>
                                <p>해외배송 가능 상품의 경우 국내배송 장바구니에 담았다가 해외배송 장바구니로 이동하여 결제하실 수 있습니다.</p>
                            </dd>
                            <dd>
                                <em>3.</em>
                                <p>파일첨부 옵션은 동일상품을 장바구니에 추가할 경우 마지막에 업로드 한 파일로 교체됩니다.</p>
                            </dd>
                        </dl>
                    </div>
                    <div className="cart-button">
                        <div className="button-box">
                            <Link to="/orders/create" className="btn btn-active f13">선택상품 주문하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Index;
