import React, {useState, useEffect} from 'react';
import SidebarMenu from "../../components/SidebarMenu";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TAX_DETAIL_URL, TAX_DELETE_URL } from '../../utils/urls';
import instance from '../../services/services';

function Show() {
    const navigate = useNavigate();
    const location = useLocation();
    const [taxDetail, setTaxDetail] = useState(null);
    const [taxList, setTaxList] = useState(null);  // taxList를 상태로 관리

    useEffect(() => {
        // location.state가 있을 때만 taxList 설정
        if (location.state?.taxList) {
            setTaxList(location.state.taxList);
        }
    }, [location.state]);

    useEffect(() => {
        const fetchTaxDetail = async () => {
            try {
                const response = await instance.post(TAX_DETAIL_URL, {
                    b_seq: location.state?.b_seq
                });
                setTaxDetail(response.data.data.data);
            } catch (error) {
                console.error('세금계산서 상세 조회 실패:', error);
            }
        };

        if (location.state?.b_seq) {
            fetchTaxDetail();
        }
    }, [location.state?.b_seq]);

    const handleDelete = async () => {
        try {
            const response = await instance.post(TAX_DELETE_URL, {
                b_seq: location.state?.b_seq
            });
            
            if (response.data.success) {
                navigate('/bill');  // 목록 페이지로 이동
            } else {
                alert('삭제에 실패했습니다.');
            }
        } catch (error) {
            console.error('세금계산서 삭제 실패:', error);
            alert('삭제 처리 중 오류가 발생했습니다.');
        }
    };

    const confirmDelete = () => {
        if (window.confirm('정말 삭제하시겠습니까?')) {
            handleDelete();
        }
    };

    // 데이터가 없을 경우 처리
    if (!location.state?.b_seq) {
        return <div>잘못된 접근입니다.</div>;
    }
    return (
        <section>
            <div className="container">
                <div className="sub-box">
                    <SidebarMenu activeItem="세금계산서"/>
                    <div className="sub-right">
                        <div className="title-box">
                            <h2>세금계산서</h2>
                        </div>
                        <div className="board-view-box">
                            <div className="view-subject">
                                <div className="left-subject order-lg-2">
                                    <h3 className="flex-0">
                                        [O.Tok] {taxList?.subject}
                                    </h3>
                                    <dl>
                                        <dd>{taxList?.order_date}</dd>
                                        <dd>{taxList?.order_name ? `${taxList.order_name.slice(0, 3)}${'*'.repeat(5)}` : '***'}</dd>
                                    </dl>
                                </div>
                                <div className="right-subject order-lg-1">
                                    <div className="state">
                                        <span className="active">답변완료</span>
                                    </div>
                                </div>
                            </div>
                            <div className="view-content">
                                <p>
                                    {taxDetail?.contents}
                                </p>
                            </div>
                            <div className="view-file">
                                <Link className="link">
                                    {taxDetail?.file_url}
                                </Link>
                            </div>
                            
                            {/* 답변내용 */}
                            <div className="view-reply">
                                <div className="view-content">
                                    <p>
                                        {taxDetail?.subject}
                                    </p>
                                </div>
                                <div className="view-file">
                                    <Link className="link">
                                        {taxDetail?.file_url}
                                    </Link>

                                </div>
                            </div>
                            <div className="button-box mt16 flex-tr">
                                <div className="w70 mr8">
                                    <Link 
                                        to="/bill/create" 
                                        state={{ 
                                            mode: 'edit',
                                            taxData: taxDetail
                                        }}
                                        className="btn btn-white sm"
                                    >
                                        수정
                                    </Link>
                                </div>
                                <div className="w70" onClick={confirmDelete}>
                                    <Link className="btn btn-white sm">
                                        삭제
                                    </Link>
                                </div>
                            </div>
                            <div className="view-button">
                                <div className="button-box">
                                    <Link to="/bill" className="btn btn-white">
                                        목록
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Show;
